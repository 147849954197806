import React from 'react'
import { CLink,CFooter } from '@coreui/react'
var pjson		 = require('../../package.json');

const ContFooter=(props)=>{
	const initRunningYear=()=>{
		const cTahunProduction	= parseInt(pjson.mydefault.prodyear) || 0;
		const cTahunNow			= (new Date()).getFullYear();

		const cSelisihTahun	= cTahunNow - cTahunProduction;
		const cCaptionTahun = cSelisihTahun<=0 
			? cTahunProduction.toString() : cTahunProduction+"-"+cTahunNow; 

		return (
			<>
			{cCaptionTahun}
			</>
		)
	}

	return (
		<CFooter className="px-3 text-black-50 justify-content-end" 
			style={{backgroundColor:"#AEAEAE55"}}>
			<div className="my-0 text-primary" style={{fontSize:12,opacity:0.5}}>
				<span>Copyright v{pjson.version} &copy; {initRunningYear()} </span>
				<CLink href="https://www.dentalivo.com/"
					target="_blank" 
					style={{textDecoration:"none",color:"inherit"}}
					rel="noopener noreferrer">
				Dentalivo
				</CLink>
				<span className="">, All Rights Reserved</span>
			</div>
		</CFooter>
    )//>
}

export default ContFooter
	