import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CFormCheck,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CPagination,
	CPaginationItem,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,CDropdownDivider,
	CFormTextarea,
	CBadge,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogform,
	MyDialogpasien,
	MyCardformpasien,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json')
const Asuransi	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uConfigappsObj			= useSelector(state => state.uConfigappsObj) || {}; 
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 15) : (uSettingObj.jml_tabel || 30);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uISDemoapp				= uConfigappsObj.level_app === "DEMO";
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--VIEWS_VARS--/
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);
	//--END VIEWS_VARS--/

	/*//--INIT_SELECTED_BEFORE--/
	const uLastinitObj 					= useSelector(state => state.gInitTabelSelected);// || {};
	const [uInitselectedObj,setInitselectedObj] = React.useState({});
	//--END INIT_SELECTED_BEFORE--*/

	//--DLGFORM--/
	const [uISDlgformshow,setDlgformshow]		= React.useState(false);
	const [uDlgformdataObj,setDlgformdataObj]	= React.useState({});
	//--END DLGFORM--/

	//--DLGVIEW--/
	//--END DLGVIEW--/

	//--DOM_VARS--/
	const uElJmlData			= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlKlikDlgformsimpan = () => {
		let vElfocus = document.getElementById("inpnamaasuransi");
		if((uDlgformdataObj.nama_asuransi||"").trim() === "") {
			vElfocus && vElfocus.focus();
			showToast("Nama Asuransi Harus Diisi !"); return;
		}
		if((uDlgformdataObj.nama_asuransi||"").trim().length < 3) {
			vElfocus && vElfocus.focus();
			showToast("Jumlah Karakter Nama Asuransi Tidak Valid (Min: 3 Huruf) !"); return;
		}
		vElfocus = document.getElementById("inpjmlterapi");
		if((parseInt(uDlgformdataObj.max_terapi)||0) < 0) {
			vElfocus && vElfocus.focus();
			showToast("Max.Terapi Tidak Valid !"); return;
		}

		apiProsessimpan();
	}
	const hdlKlikEdit = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionUpper = (uDatamainArr[vArridx].nama_asuransi||"").toUpperCase();
		setRowselect(vIdxRow);

		uDlgformdataObj.nama_asuransi 	= uDatamainArr[vArridx].nama_asuransi;
		uDlgformdataObj.keterangan 		= uDatamainArr[vArridx].keterangan;
		uDlgformdataObj.max_terapi 		= parseInt(uDatamainArr[vArridx].max_terapi)||0;

		uDlgformdataObj.headers 		= "Edit Data: "+vCaptionUpper;
		setDlgformshow(true)
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionUpper = (uDatamainArr[vArridx].nama_asuransi||"").toUpperCase();
		setRowselect(vIdxRow);

		if(await Konfirm("Hapus Data: <B>"+vCaptionUpper+"</B>"+
			"<BR><BR>Apakah Anda Yakin ?"+
			"")) apiProseshapus(_IDTABEL);
	}
	//--END HANDEL--/

	//--INIT_FUNC--/
	//--END INIT_FUNC--/

	//--CONTENT_FUNC--/
	const contentMain = () => {
		if(uHtml500msg) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_asuransi || "");
		const vKeywordcolor = uSettingObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<div className="mx-lg-4">
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row align-top text-center classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
				<CRow className="mx-0">
				<CCol md="4" className="text-start">{uBahasaObj.caption_namaasuransi||"Nama Asuransi"}</CCol>
				<CCol md="2" className="">{uBahasaObj.caption_maxterapi||"Maks.Terapi"}/Billing</CCol>
				<CCol md="4" className="text-start">Keterangan</CCol>
				<CCol md="2" className="">&middot;</CCol>
				</CRow>
			</th>
			</tr>
			<tr className="d-md-none classfontsmaller">
				<th width={30} className="p-0"/>
				<th className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_input,userinput,nama_asuransi,keterangan,max_terapi,
				init_process,
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (nama_asuransi||"").toUpperCase();

			const vNamaasuransi	= vKeyword==="" ? (nama_asuransi||"") 
				: (nama_asuransi||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vKeterangan	= vKeyword==="" ? (keterangan||"-") 
				: (keterangan||"-").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUserinput	= vKeyword==="" ? UFunc.Usercaptionfilter((userinput||"UNDF"),1) 
				: (UFunc.Usercaptionfilter((userinput||"UNDF"),1)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uRowselect===vKeys?" classrowselect":"")}>
				<td className="px-1 pe-0">{vNumber}.</td>

				<td className="px-1">
				<CRow className="mx-0">
				<CCol xs="12" md="4" className="px-1 text-start">
					<div className="d-flex justify-content-between">
						<span className="text-capitalize text-info">{UFunc.renderHTML(vNamaasuransi)}</span>
						
						<CDropdown className="d-md-none">
						<CDropdownToggle caret={false} size="sm" className="p-0" color="transparent">
							<CIcon icon="cilOptions" height={18}/>
						</CDropdownToggle>
						<CDropdownMenu>
							<CDropdownItem onClick={()=>hdlKlikEdit(id)}>
								<CIcon className="classikonedit align-middle ms-0 me-2" height={25}/>
								{uBahasaObj.caption_editdata||"Edit Data"}
							</CDropdownItem>
							
							{(uIsHapusallow) && (
							<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
								<CIcon className="classikonhapus align-middle ms-0 me-2" height={25}/>
								{uBahasaObj.caption_hapusdata||"Hapus Data"}
							</CDropdownItem>
							)}
						</CDropdownMenu>
						</CDropdown>
					</div>

					<span className="text-danger d-none d-md-block classfontsmaller">
					<small>
						[{UFunc.WaktuAngka(tgl_input||"")}
						<span className="mx-1">&middot;</span>
						{UFunc.renderHTML(vUserinput)}]
					</small>
					</span>
				</CCol>

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>
				
				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_maxterapi||"Max.Terapi"}</CCol>
				<CCol xs="8" md="2" className="px-1 text-md-center">
					{(parseInt(max_terapi)||0) > 0 ? (
						<>
						<b>{UFunc.formatAngka(max_terapi)}</b> / Billing
						</>
					) : (<small className="fst-italic text-muted">Unlimited</small>)}
				</CCol>
				
				<CCol xs="3" className="px-1 d-md-none text-start">{uBahasaObj.word_keterangan||"Keterangan"}</CCol>
				<CCol xs="9" md="4" className="px-1 text-md-start">
					<small className="fst-italic">{UFunc.renderHTML(vKeterangan)}</small>
				</CCol>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_inputby||"Inputby"}</CCol>
				<CCol xs="7" className="px-1 text-danger d-md-none classfontsmaller">
					<small>[{UFunc.WaktuAngka(tgl_input||"")} &middot; {UFunc.renderHTML(vUserinput)}]</small>
				</CCol>

				<CCol md="2" className="px-0 text-md-center d-none d-md-block">
					<CTooltip content={"--"+(uBahasaObj.caption_editdata||"Edit Data")+": "+vCaptionUpper}>
					<CLink onClick={()=>hdlKlikEdit(id)} className="classikontabel classikonedit classcursorpointer"/>
					</CTooltip>

					{uIsHapusallow && (
						<>
						<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"Hapus Data")+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikHapus(id)} className="classikontabel classikonhapus classcursorpointer"/>
						</CTooltip>
						</>
					)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		</div>
		)//>
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//>

		return (
		<CForm className="mx-lg-5">
		<CRow className="my-2">
		<CCol xs="12" className="text-start">{uBahasaObj.caption_namaasuransi||"Nama Asuransi"}</CCol>
		<CCol className="">
			<CFormInput size="sm"
				maxLength={100}
				autoComplete="off"
				className="text-capitalize"
				value={uDlgformdataObj.nama_asuransi||""}
				onChange={(e)=>{uDlgformdataObj.nama_asuransi=(e.target.value);setEfekview(!uEfekview)}}
				id="inpnamaasuransi"/>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="12" className="text-start">{uBahasaObj.caption_maxterapi||"Maks.Terapi"}</CCol>
		<CCol md="7">
			<CInputGroup size="sm">
			<CFormInput size="sm" type="number"
				min={1} max={0}
				className="text-end"
				value={uDlgformdataObj.max_terapi}
				onFocus={(e)=>e.target.select()}
				onChange={(e)=>{uDlgformdataObj.max_terapi=e.target.value;setEfekview(!uEfekview)}}
				id="inpjmlterapi"/>
			<CInputGroupText className="bg-transparent"> / </CInputGroupText>
			<CInputGroupText>Billing</CInputGroupText>
			</CInputGroup>
		</CCol>
		<CCol xs="12">
			<div className="classpetunjuk">*) Set Nilai NOL, jika Tidak Terbatas</div>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="12" className="text-start">{uBahasaObj.word_keterangan||"Keterangan"}</CCol>
		<CCol className="">
			<CFormTextarea size="sm"
				autoComplete="off"
				maxLength={255}
				rows={3}
				className="text-capitalize"
				value={UFunc.br2nl(uDlgformdataObj.keterangan||"")}
				onChange={(e)=>{uDlgformdataObj.keterangan=(e.target.value);setEfekview(!uEfekview)}}
				onKeyDown={(e)=>{
					if(e.keyCode===13)
						if(!e.shiftKey) {
							e.preventDefault();
							document.getElementById("btnDialogSimpan") && 
								document.getElementById("btnDialogSimpan").focus();
							return;
						}
				}}
				id="inpnamaasuransi"/>
			<div className="classpetunjuk">*) Contact Person / No.Rek Transfer / dsb</div>
		</CCol>
		</CRow>
		</CForm>
		)
	}
	//--END CONTENT_FUNC--/

	//--API--/
	const apiLoaddata = () => {
		setHtml500msg();
		setDatamainArr([]);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vKeyword 		= uKeywordObj.kwd_asuransi || "";
		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_asuransi/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				//console.log("(Asuransi - apiLoaddata) uPageActive : "+(uPageActive));
				const vTmpArr = JSON.parse(output_string.dataobject||"[]");
				setDatamainArr(vTmpArr);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Asuransi - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Asuransi - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = () => {
		const vIDTabel	= parseInt(uIDselect)||0;
		const vBtnsimpan= document.getElementById("btnDialogSimpan");
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,vIDTabel);

		vBtnsimpan && (vBtnsimpan.disabled = true)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(Pekerjaan - apiProsessimpan) LEWaT "); 
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vIDTabel,
			send_namaasuransi : uDlgformdataObj.nama_asuransi,
			send_keterangan : uDlgformdataObj.keterangan,
			send_maxterapi : uDlgformdataObj.max_terapi||0,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_asuransi/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vBtnsimpan && (vBtnsimpan.disabled = false)
			if(output_string.sukses) {
				if(vIDTabel <= 0) {
					const vTmpObj = {
						id: output_string.tabelid||"255",
						tgl_input: "",
						userinput: uTokenObj.usercaption,
						nama_asuransi:uDlgformdataObj.nama_asuransi,
						max_terapi:uDlgformdataObj.max_terapi,
						keterangan:UFunc.nl2br(uDlgformdataObj.keterangan),
					}
					setDatamainArr([vTmpObj,...uDatamainArr]);

					uDlgformdataObj.nama_asuransi 	= "";
					uDlgformdataObj.keterangan 		= "";
					uDlgformdataObj.max_terapi 		= 0;
					setPageActive(1); setRowselect(-1);

					if(!isMobile) {
						document.getElementById("inpnamaasuransi") && 
							document.getElementById("inpnamaasuransi").focus()
					}
					showToast((uBahasaObj.pesan_sukses || "Data Berhasil Disimpan.."),"SUKSES");
				} else {
					uDatamainArr[vArridx].nama_asuransi	= uDlgformdataObj.nama_asuransi;
					uDatamainArr[vArridx].keterangan	= UFunc.nl2br(uDlgformdataObj.keterangan);
					uDatamainArr[vArridx].max_terapi	= uDlgformdataObj.max_terapi;
					setDlgformshow(false);
				}
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Asuransi - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setDlgformshow(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vBtnsimpan && (vBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(Asuransi - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		const vIDTabel = parseInt(_IDTABEL)||0;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,vIDTabel)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vIDTabel,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_asuransi/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr.splice(vArridx,1);
				setRowselect(-1); setIDselect(0);

				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pekerjaan - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Pekerjaan - apiProseshapus) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/


	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	//const vPageinit	= uPageActive;
		//console.log("(Asuransi - []) uLastinitObj.initPage => "+(uLastinitObj.initPage))

		//--PAGE_LOAD_ALL--/
		apiLoaddata();
		//--END PAGE_LOAD_ALL--*/

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
    		setDatamainArr([]);
			setDlgformshow(false);
			setPageActive(1);
    	}
	},[])
	React.useEffect(()=>{ 
		setRowselect(-1);
		setIDselect(0);
	},[uPageActive]);
	React.useEffect(()=>{ 
		setJmlData(uDatamainArr.length);
	},[uDatamainArr,uDatamainArr.length])
	React.useEffect(()=>{ 
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/

		/*const vKeyword 		= uKeywordObj.kwd_asuransi || "";
		if(vKeyword.length < 3) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uElJmlData,uJmlData])
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		//setElJmlData(null);
		setDatamainArr([]);
		setJmlData(0);
		setIDselect(0);
		setRowselect(-1);

		//console.log("(Asuransi - [uHandelView]) uHandelView => "+uHandelView)
		if(parseInt(uPageActive) !== 1) {
			setPageActive(1);
		} else apiLoaddata();
	},[uHandelView])
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHtml500msg) return;

		uDlgformdataObj.headers 	= "Tambah Data";
		uDlgformdataObj.max_terapi	= 0;
		uDlgformdataObj.nama_asuransi	= "";
		uDlgformdataObj.keterangan	= "";
		setDlgformshow(true);
	},[uHandelTambah])
	React.useEffect(()=>{
		if(!uISDlgformshow) {setIDselect(0);setDlgformdataObj({});return;}

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			const vElfocus = document.getElementById("inpnamaasuransi");
			if(!isMobile) vElfocus && vElfocus.focus();
		},350);
	},[uISDlgformshow])

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	return (
		<>
		<CCard className="classbgcard">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<div>
		<b className="align-middle">{uBahasaObj.caption_listdata||"List Data"}</b>
		<span className="mx-1 d-md-none">&middot;</span>
		<small className="d-md-none text-primary align-middle">{(()=>{
			/*const vKeyword = (uKeywordObj.kwd_asuransi||"").trim();
			if(vKeyword.length < 3) return (<>&#8734;</>);//-*/

			return (<>[{UFunc.formatAngka(uJmlData)}]</>)
		})()}</small>
		</div>
		<span className="text-muted classfontsmaller">
			<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
		</span>
		<small className="d-none">({uJmlData} - {uMaxData})</small>
		</CCardHeader>
		
		<CCardBody className="px-0">{contentMain()}</CCardBody>

		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/> 
		</CCardFooter>
		)}
		</CCard>

		<MyDialogform
			options={{centered:false}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uISDlgformshow} 
			dialogHeader={uDlgformdataObj.headers||""}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>
		</>
	)//>
}	

export default Asuransi