import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CFormCheck,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CPagination,
	CPaginationItem,
	CLink,
	CTooltip,
	CDropdown,
	CDropdownToggle,
	CDropdownMenu,
	CDropdownItem,CDropdownDivider,
	CSpinner,
	CModal,
	CModalHeader,
	CModalBody,
	CModalTitle,
	CModalFooter,
	CButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogform,
	MyDialogview,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json')
const Kompetensi 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 25) : (uSettingObj.jml_tabel || 50);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	const uWindowheight		= window.innerHeight;

	//--VIEWS_VARS--/
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uTerapiunselectArr,setTerapiunselectArr]= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);

	const uDatacabangArr				= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const [uEfekview2,setEfekview2] 	= React.useState(false);
	//--END VIEWS_VARS--/

	//---FORM_VARS--/
	const [uIsDlgformshow,setDlgformshow]			= React.useState(false);
	const [uInpnamakompetensi,setInpnamakompetensi] = React.useState("");
	const [uDlgformheader,setDlgformheader]			= React.useState("");
	const [uDlgformdataObj,setDlgformdataObj]		= React.useState({});
	const [uISTerapimobiledone,setTerapimobiledone]	= React.useState(true);
	//---END FORM_VARS--/

	//---VIEWS--/
	const [uIsDlgviewshow,setDlgviewshow]			= React.useState(false);
	const [uDlgviewheader,setDlgviewheader]			= React.useState("");
	//---END VIEWS--/

	//---MODAL--/
	const [uIsModalshow,setModalshow]				= React.useState(false);
	const [uModalheader,setModalheader]				= React.useState("");
	const [uInpterapiunselectArr,setInpterapiunselectArr]= React.useState([]);
	const [uInpterapiselectArr,setInpterapiselectArr]= React.useState([]);
	const [uISModalhapus,setModalhapus]				= React.useState(true);
	//---END MODAL--/

	//--DOM_VARS--/
	const uElJmlData	= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlToggleDlgform = () =>{ setDlgformshow(false) }
	const hdlToggleDlgview = () =>{ setDlgviewshow(false) }

	const hdlKlikEdit = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionUpper = (uDatamainArr[vIdxObj].kompetensi_caption||"").toUpperCase();
		setRowselect(vIdxRow);

		setInpnamakompetensi(uDatamainArr[vIdxObj].kompetensi_caption||"");

		setDlgformheader("Edit Data: "+vCaptionUpper);
		uDlgformdataObj.jenis = "KOMPETENSI";
		setDlgformshow(true)
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionUpper = (uDatamainArr[vIdxObj].kompetensi_caption||"").toUpperCase();
		setRowselect(vIdxRow);

		if(await(Konfirm("Hapus Data: <B>"+vCaptionUpper+"</B>"+
			"<BR><BR>Apakah Anda Yakin ?"+
			""))) apiProseshapus(_IDTABEL);
	}
	const hdlKlikview = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionUpper = (uDatamainArr[vIdxObj].kompetensi_caption||"").toUpperCase();
		setRowselect(vIdxRow);
		setDlgviewheader("Data Kompetensi Terapi: "+vCaptionUpper);

		const vDataviewArr = uDatamainArr[vIdxObj].items_arr || [];
		if(vDataviewArr.length > 0) {
			setDlgviewshow(true);
			return;
		}

		apiLoaditem(_IDTABEL);
	}
	const hdlKlikEditterapi = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionUpper = (uDatamainArr[vIdxObj].kompetensi_caption||"").toUpperCase();
		setRowselect(vIdxRow);

		setModalheader((isMobile ? "" :"Form Kompetensi Terapi: ")+vCaptionUpper);
		
		apiLoadform(_IDTABEL);
	}
	const hdlChangeUnselect = (_EVN) => {
		const vValueArr = Array.from(_EVN.target.selectedOptions, 
			option => option.value);
		setInpterapiunselectArr(vValueArr);
	}
	const hdlChangeselectall = (_EVN) => {
		let vValueArr = []; 

		const vTabelID 			= parseInt(uIDselect||"0");
		const vIdxObj			= UFunc.getObjectIndeks(uDatamainArr,vTabelID);
		const vTerapiselectArr  = uDatamainArr[vIdxObj].items_arr || [];
		if(_EVN.target.checked) {
			vValueArr = vTerapiselectArr.map(vItems => vItems.id);
		}

		setInpterapiselectArr(vValueArr);
	}
	const hdlChangeselect = (_EVN) => {
		const vValueArr = Array.from(_EVN.target.selectedOptions, 
			option => option.value);
		setInpterapiselectArr(vValueArr);
	}
	const hdlKlikprosesunselect = () => {
		if(uInpterapiunselectArr.length <= 0) return;

		apiProsesunselect();
	}
	const hdlKlikhapusselect = () => {
		if(uInpterapiselectArr.length <= 0) return;

		apiProsesselect();
	}
	const hdlKlikTambahmobile = () => {
		setDlgformheader("Pilih Terapi Kompetensi");
		uDlgformdataObj.jenis	= "PILIHTERAPI";
		setDlgformshow(true)
	}
	const hdlKlikDlgsimpan = () => {
		if(uDlgformdataObj.jenis === "KOMPETENSI")
			{ prosesKlikkompetensi(); return }

		if(uDlgformdataObj.jenis === "PILIHTERAPI")
			{ prosesKlikterapipilih(); return }
	}
	const prosesKlikkompetensi = () => {
		let vElFocus;
		vElFocus = document.getElementById("inpnamakompetensi");
		if(uInpnamakompetensi.trim()==="") {
			vElFocus.focus();
			showToast("Nama Kompetensi Harus Diisi !");return;
		}

		apiProsessimpan(uIDselect);
	}
	const prosesKlikterapipilih = () => {
		if((uDlgformdataObj.itemid_arr||[]).length <= 0) return;

		const vTerapiselectArr = uDlgformdataObj.itemid_arr || []; 
		//console.log("(Kompetensi - prosesKlikterapipilih) vTerapiselectArr => "+(vTerapiselectArr).join(", "));
		setInpterapiunselectArr(vTerapiselectArr);
		setTerapimobiledone(true);
	}

	//--END HANDEL--/

	//--INIT--/
	const initFormAwal = () => {
		//console.log("(Kompetensi - initFormAwal) uDatarole => "+JSON.stringify(uDatarole));
		setInpnamakompetensi(""); setDlgformdataObj({});
	}
	const initModalawal = () => {
		setInpterapiunselectArr([])
		setInpterapiselectArr([])
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_kompetensi || "");
		const vKeywordcolor = uSettingObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<div className="mx-lg-5 px-lg-5">
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width={40} className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="8" className="text-start">{uBahasaObj.caption_namakompetensi||"Nama Kompetensi"}</CCol>
			<CCol md="4" className="">&middot;</CCol>
			</CRow>
			</th>
			</tr>
			
			<tr className="d-md-none">
			<th width={30} className="p-0"/>
			<th className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_input,userinput,kompetensi_caption,
				init_process,
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (kompetensi_caption||"").toUpperCase();

			const vKompetensicaption	= vKeyword==="" ? (kompetensi_caption||"") 
				: (kompetensi_caption||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUserinput	= vKeyword==="" ? UFunc.Usercaptionfilter((userinput||"UNDF"),1) 
				: (UFunc.Usercaptionfilter((userinput||"UNDF"),1)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uRowselect===vKeys?" classrowselect":"")}>
				<td className="px-1">{vNumber}.</td>

				<td className="px-1">
				<CRow className="mx-0">
				<CCol xs="12" md="8" className="px-1 text-start">
					<div className="d-flex justify-content-between">
						<div className="text-capitalize text-info">{UFunc.renderHTML(vKompetensicaption)}</div>

						{(init_process||false)===true ? (
							<CSpinner color="primary" size="sm" className="d-md-none mx-1"/>
						) : (
						<CDropdown className="d-md-none">
							<CDropdownToggle caret={false} className="p-0" color="transparent">
								<CIcon icon="cilOptions" height={18}/>
							</CDropdownToggle>
							<CDropdownMenu>
							<CDropdownItem onClick={()=>hdlKlikEdit(id)}
								className="classcursorpointer">
								<CIcon className="classikonedit align-middle ms-0 me-2" height={25}/>
								{uBahasaObj.caption_editdata||"Edit Data"}
							</CDropdownItem>
							{(!isMobile) && (
							<CDropdownItem onClick={()=>hdlKlikview(id)} className="classcursorpointer">
								<CIcon icon="cilFindInPage" className="classikonbox align-middle ms-0 me-2" height={25}/>
								Lihat Data Terapi
							</CDropdownItem>
							)}
							<CDropdownItem onClick={()=>hdlKlikEditterapi(id)}
								className="classcursorpointer">
								<CIcon icon="cilFax" className="classikonbox align-middle ms-0 me-2" height={25}/>
								Edit Terapi Kompetensi
							</CDropdownItem>
							
							{(uIsHapusallow||false)===true && (
							<>
							<CDropdownDivider/>
							<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
								<CIcon className="classikonhapus align-middle ms-0 me-2" height={25}/>
								{uBahasaObj.caption_hapusdata||"Hapus Data"}
							</CDropdownItem>
							</>
							)}
							</CDropdownMenu>
						</CDropdown>
						)}
					</div>

					<span className="text-danger d-none d-md-block classfontsmaller">
						<small>[{UFunc.WaktuAngka(tgl_input||"")}
						<span className="mx-1">&middot;</span>
						{UFunc.renderHTML(vUserinput)}]
						</small>
					</span>
				</CCol>

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_inputby||"Inputby"}</CCol>
				<CCol xs="8" className="px-1 text-danger d-md-none classfontsmaller">
					<small>[{UFunc.WaktuAngka(tgl_input||"")} &middot; {UFunc.renderHTML(vUserinput)}]</small>
				</CCol>

				<CCol md="4" className="px-0 text-md-center d-none d-md-block">
					{(init_process||false)===true ? (
						<CSpinner color="primary" size="sm"/>
					) : (
					<>
					<CTooltip content={"--"+(uBahasaObj.caption_editdata||"Edit Data")+": "+vCaptionUpper}>
					<CLink onClick={()=>hdlKlikEdit(id)} className="classikontabel classikonedit classcursorpointer"/>
					</CTooltip>

					<CDropdown className="">
					<CDropdownToggle caret={false} size="sm" className="p-0" 
						color="transparent">
						<CIcon icon="cilChevronBottom" className="classikonbox" height={25}/>
					</CDropdownToggle>
					<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikview(id)}
							className="classcursorpointer">
							<CIcon icon="cilFindInPage" className="classikonbox mx-0 me-2" height={25}/>
							{"Lihat Data Terapi"}
						</CDropdownItem>
						<CDropdownItem onClick={()=>hdlKlikEditterapi(id)}
							className="classcursorpointer">
							<CIcon icon="cilFax" className="classikonbox mx-0 me-2" height={25}/>
							{"Edit Terapi Kompetensi"}
						</CDropdownItem>
						
						{(uIsHapusallow||false)===true && (
						<>
						<CDropdownDivider/>
						<CDropdownItem onClick={()=>hdlKlikHapus(id)}
							className="classcursorpointer">
							<CIcon className="classikonhapus me-2 align-middle" height={25}/>
							{uBahasaObj.caption_hapusdata||"Hapus Data"}
						</CDropdownItem>
						</>
						)}
					</CDropdownMenu>
					</CDropdown>
					</>
					)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		</div>
		)//>
	}
	const contentDlgform = () => {
		if(!uIsDlgformshow) return (<></>)//>

		if(uDlgformdataObj.jenis === "KOMPETENSI") return contentFormkompetensi();
		if(uDlgformdataObj.jenis === "PILIHTERAPI") return contentFormterapi();

		return (<></>)//>
	}
	const contentFormkompetensi = () => {
		if(uDlgformdataObj.jenis !== "KOMPETENSI") return (<></>)//>

		return (
		<CForm className="mx-lg-5">
		<CRow className="my-2">
		<CCol xs="12" className="">{uBahasaObj.caption_namakompetensi||"Nama Kompetensi"}</CCol>
		<CCol className="">
			<CFormInput size="sm"
				maxLength={100}
				className="text-capitalize"
				value={uInpnamakompetensi}
				onChange={(e)=>setInpnamakompetensi(e.target.value)}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpnamakompetensi"/>
		</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const contentFormterapi = () => {
		if(uDlgformdataObj.jenis !== "PILIHTERAPI") return (<></>)//>

		const vArrIDcheck	= uDlgformdataObj.itemid_arr || [];
		return (
		<CForm className="">
		{uTerapiunselectArr.map((vItems,vKeys)=>{
			return (
			<CRow className="mx-0 py-2 classborderbottom" key={vKeys}>
				<CCol xs="1" className="px-1 text-end">{vKeys+1}.</CCol>
				<CCol xs="11" className="px-1 d-flex">
					<CFormCheck size="sm"
						className="mx-2"
						checked={vArrIDcheck.includes(vItems.id)}
						onChange={(e)=>{
							if(e.target.checked === false) {
								const vArridx = vArrIDcheck.indexOf(vItems.id);
								(uDlgformdataObj.itemid_arr||[]).splice(vArridx,1);
								setEfekview(!uEfekview);

								return;
							}
							uDlgformdataObj.itemid_arr = [...vArrIDcheck,vItems.id];
							setEfekview(!uEfekview);
						}}
						id={"inpterapi"+vItems.id}/>
					<label for={"inpterapi"+vItems.id} className="ps-2">
						{vItems.terapi_caption}
						<span className="mx-1">&middot;</span>
						Rp{UFunc.formatAngka(vItems.terapi_tarif)}
					</label>
				</CCol>
			</CRow>
			)
		})}
		</CForm>
		)//>
	}
	const contentDlgview = () => {
		if(!uIsDlgviewshow) return (<></>)//>

		const vTabelID 	= parseInt(uIDselect||"0");
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,vTabelID);
		const vDataviewArr = uDatamainArr[vIdxObj].items_arr || [];
		if(vDataviewArr.length <= 0) return (<>UNDF</>)

		return (
		<div className="mx-lg-5 px-3 px-lg-5">
		{vDataviewArr.map((vItems,vKeys)=>{
			return (
			<CRow className="classborderbottom py-2" key={vKeys}>
				<CCol xs="1" className="px-1 text-end">{vKeys+1}.</CCol>
				
				<CCol xs="8" className="px-1 text-start text-info">
					{vItems.terapi_caption}
				</CCol>

				<CCol xs="3" className="px-1 text-end">
					<small>Rp {UFunc.formatAngka(vItems.terapi_tarif)}</small>
				</CCol>
			</CRow>
			)
		})}
		</div>
		)//>
	}
	const contentModal = () => {
		if(!uIsModalshow) return (<></>)//>

		const vTabelID 			= parseInt(uIDselect||"0");
		const vIdxObj			= UFunc.getObjectIndeks(uDatamainArr,vTabelID);
		const vTerapiselectArr  = uDatamainArr[vIdxObj].items_arr || [];

		if(isMobile) return (
		<CCard>
			<CCardHeader className="py-1 classfontsmaller d-flex justify-content-between">
				<div>Daftar Terapi</div>

				<CLink onClick={()=>hdlKlikTambahmobile()} 
					className="classcursorpointer classikontabel classikontambah"/>
			</CCardHeader>
			<CCardBody className="px-0 px-md-2">
			<small>
			{vTerapiselectArr.map((vItems,vKeys)=>{
			return (
			<CRow key={vKeys} className="py-2 classborderbottom mx-0">
				<CCol xs="1" className="px-1 text-end">{vKeys+1}.</CCol>
				<CCol xs="1" className="px-1 text-center">
					<CLink onClick={async()=>{
							if(await Konfirm("Apakah Anda Yakin ?")) {
								setInpterapiselectArr([vItems.id]); 
								setModalhapus(true);	
							}
						}}
						className="classcursorpointer classikontabel classikonhapus"/>
				</CCol>
				<CCol xs="10" className="">
					{vItems.terapi_caption||"UNDF"} 
					<span className="mx-1">&middot;</span>
					<span className="text-primary">Rp{UFunc.formatAngka(vItems.terapi_tarif)}</span>
				</CCol>
			</CRow>
			)
			})}
			</small>
			</CCardBody>
		</CCard>
		)//>

		return (
		<div className="d-flex justify-content-between align-items-center mx-md-2 mx-lg-5">
			<CCard style={{
					minWidth:300,width:"calc((100% - 50px)/2)",
					height:uWindowheight-110
				}}>
			<CCardHeader>Terapi Available</CCardHeader>
			<CCardBody>
				<CFormSelect multiple
					value={uInpterapiunselectArr}
					onChange={(e)=>hdlChangeUnselect(e)}
					style={{height:"100%"}}
					id="inpterapiidavailable">
				{uTerapiunselectArr.map((vItems,vKeys)=>{
					return (
					<option key={vKeys} value={vItems.id}>
						{vItems.terapi_caption||"UNDF"} &middot; (Rp {UFunc.formatAngka(vItems.terapi_tarif)})
					</option>
					)
				})}
				</CFormSelect>
			</CCardBody>
			</CCard>

			<div align="center">
				<CButton size="sm"
					onClick={()=>hdlKlikprosesunselect()}
					color="transparent"
					className="m-2 p-0"
					id="btnPilihterapi">
					<CIcon icon="cilArrowCircleRight" height={30}/>
				</CButton>
				<CButton size="sm"
					onClick={()=>hdlKlikhapusselect()}
					color="transparent"
					className="my-1 p-0"
					id="btnHapusterapi">
					<CIcon icon="cilArrowCircleLeft" height={30}/>
				</CButton>
			</div>

			<CCard style={{
					minWidth:300,width:"calc((100% - 50px)/2)",
					height:uWindowheight-110
				}}>
			<CCardHeader className="d-flex justify-content-between align-items-center">
				<div>Terapi Kompetensi</div>
				<CFormCheck  value="ALL" label="Select All" 
					className="classfontsmaller"
					onChange={(e)=>hdlChangeselectall(e)}
					id="idcheckall"/>
			</CCardHeader>
			<CCardBody>
				<CFormSelect multiple
					value={uInpterapiselectArr}
					onChange={(e)=>hdlChangeselect(e)}
					style={{height:"100%"}}
					id="inpterapiidselected">
				{vTerapiselectArr.map((vItems,vKeys)=>{
					return (
					<option key={vKeys} value={vItems.id}>
						{vItems.terapi_caption||"UNDF"} (Rp {UFunc.formatAngka(vItems.terapi_tarif)})
					</option>
					)
				})}
				</CFormSelect>
			</CCardBody>
			</CCard>
		</div>
		)//>
	}
	//--END CONTENT--/

	//--API--/
	const apiLoaddata = () => {
		setHtml500msg("");

		setJmlData(0);
		setDatamainArr([]);
		setIDselect(0);
		setRowselect(-1);
		setPageActive(1);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= []
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vKeyword 	= uKeywordObj.kwd_kompetensi || "";
		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kompetensi/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpObj = JSON.parse(output_string.dataobject||"[]");
				setDatamainArr(vTmpObj);
				setJmlData((parseInt(vTmpObj.length)||0));

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Kompetensi - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Kompetensi - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;

		const vBtnsimpan= document.getElementById("btnDialogSimpan");
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		vBtnsimpan && (vBtnsimpan.disabled = true)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(Kompetensi - apiProsessimpan) LEWaT "); 
		let vTimeout = setTimeout(()=>{
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_namakompetensi : uInpnamakompetensi,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kompetensi/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vBtnsimpan && (vBtnsimpan.disabled = false)
			if(output_string.sukses) {
				if(_IDTABEL <= 0) {
					const vTmpObj = {
						id: output_string.tabelid||"255",
						tgl_input: "",
						userinput: uTokenObj.usercaption,
						kompetensi_caption:uInpnamakompetensi,
					}
					setDatamainArr([vTmpObj,...uDatamainArr]);
					setJmlData(uJmlData+1);

					setInpnamakompetensi("");
					setPageActive(1); setRowselect(-1);
					if(!isMobile) {
						document.getElementById("inpnamakompetensi") && 
							document.getElementById("inpnamakompetensi").focus()
					}
					showToast((uBahasaObj.pesan_sukses || "Data Berhasil Disimpan.."),"SUKSES");
				} else {
					uDatamainArr[vIdxObj].kompetensi_caption	= uInpnamakompetensi;

					hdlToggleDlgform();
				}
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Kompetensi - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setDlgformshow(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vBtnsimpan && (vBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(Kompetensi - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			setEfekview(!uEfekview);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kompetensi/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr.splice(vIdxObj,1);
				setRowselect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
			
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Kompetensi - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Kompetensi - apiProseshapus) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoaditem = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		uDatamainArr[vIdxObj].init_process = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		console.log("(Kompetensi - apiLoaditem) LEWaT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_itemonly : "YA",
			send_tabelid : _IDTABEL,
			send_cabangid : uTokenObj.user_cabangid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kompetensi/ld_item";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vIdxObj].init_process = false;
			if(output_string.tampil) {
				const vTmpArr 	= JSON.parse(output_string.itemslist||"[]");
				if(vTmpArr.length <= 0) {
					showToast("Data Tidak Ada !");
					return;
				}
				uDatamainArr[vIdxObj].items_arr 	= vTmpArr;
				uDatamainArr[vIdxObj].init_process 	= false;
				setDlgviewshow(true);
			} else  {
				setEfekview2(!uEfekview2);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Kompetensi - apiLoaditem) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			uDatamainArr[vIdxObj].init_process = false;
			setEfekview2(!uEfekview2);
			console.log("(Kompetensi - apiLoaditem) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoadform = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		const vIdxObj		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vItemsonly 	= uTerapiunselectArr.length > 0 ? "YA" : "TIDAK";
		uDatamainArr[vIdxObj].init_process = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		console.log("(Kompetensi - apiLoaditem) LEWaT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_itemonly : vItemsonly,
			send_tabelid : _IDTABEL,
			send_cabangid : uTokenObj.user_cabangid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kompetensi/ld_item";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vIdxObj].init_process = false;
			if(output_string.tampil) {
				setTerapiunselectArr(JSON.parse(output_string.terapiall||JSON.stringify(uTerapiunselectArr)));

				const vTmpArr 	= JSON.parse(output_string.itemslist||"[]");
				uDatamainArr[vIdxObj].items_arr 	= vTmpArr;
				uDatamainArr[vIdxObj].init_process 	= false;

				setModalshow(true);
			} else  {
				setEfekview2(!uEfekview2);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Kompetensi - apiLoaditem) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			uDatamainArr[vIdxObj].init_process = false;
			setEfekview2(!uEfekview2);
			console.log("(Kompetensi - apiLoaditem) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesunselect = () => {
		const vTabelID = parseInt(uIDselect)||0; if(vTabelID <= 0) return;

		const vElButton = document.getElementById("btnDialogSimpan");
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,vTabelID)
		vElButton && (vElButton.disabled=true);
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		//console.log("(Kompetensi - apiProsesunselect) uInpterapiunselectArr => "+uInpterapiunselectArr.join(", "));
		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelID,//-->id_kompetensi
			send_terapiidlist:uInpterapiunselectArr.join(","),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kompetensi/pr_tambahterapi";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			vElButton && (vElButton.disabled=false);
			setLoading(false);
			if(output_string.sukses) {
				const vTerapiselectnewArr = uTerapiunselectArr.filter(vItems=>
					uInpterapiunselectArr.includes(vItems.id.toString()));
				const vTerapiunselectnewArr = uTerapiunselectArr.filter(vItems=>
					!uInpterapiunselectArr.includes(vItems.id.toString()));

				uDatamainArr[vIdxObj].items_arr = 
					[...vTerapiselectnewArr,...uDatamainArr[vIdxObj].items_arr];

				setInpterapiunselectArr([]);
				setTerapiunselectArr(vTerapiunselectnewArr);
				hdlToggleDlgform();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Kompetensi - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElButton && (vElButton.disabled=false);
			setLoading(false);
			console.log("(Kompetensi - apiProseshapus) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesselect = () => {
		const vTabelID = parseInt(uIDselect)||0;
		if(vTabelID <= 0) return;

		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,vTabelID)
		const vTerapiselectArr = uDatamainArr[vIdxObj].items_arr || []
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelID,//-->id_kompetensi
			send_terapiidlist:uInpterapiselectArr.join(","),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kompetensi/pr_kurangterapi";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){ setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				const vTerapiunselectnewArr = vTerapiselectArr.filter(vItems=>
					uInpterapiselectArr.includes(vItems.id.toString()));
				const vTerapiselectnewArr = vTerapiselectArr.filter(vItems=>
					!uInpterapiselectArr.includes(vItems.id.toString()));

				uDatamainArr[vIdxObj].items_arr = vTerapiselectnewArr || [];

				setInpterapiselectArr([]);
				setTerapiunselectArr([...vTerapiunselectnewArr,...uTerapiunselectArr]);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Kompetensi - apiProsesselect) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Kompetensi - apiProsesselect) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDlgformshow(false); setDlgviewshow(false); setModalshow(false);
    		setDatamainArr([]);
    		setTerapiunselectArr([]);
    	}
	},[])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		/*const vKeyword = uKeywordObj.kwd_kompetensi || "";
		if(vKeyword.length <= 2) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData])
	React.useEffect(()=>{ setRowselect(-1); },[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoaddata();
	},[uHandelView])
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHtml500msg) return;

		setDlgformheader((uBahasaObj.word_tambah||"Tambah")+" "+uBahasaObj["menus_"+uActiveroute.toLowerCase()]);
		uDlgformdataObj.jenis	= "KOMPETENSI";
		setIDselect(0);

		setDlgformshow(true)
	},[uHandelTambah])
	/*React.useEffect(()=>{
		//console.log("("+cComponentName+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;
		
		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom])//-*/
	React.useEffect(()=>{
		if(!uIsDlgformshow) { initFormAwal(); return; }

		//console.log("("+cComponentName+"[uIsDlgformshow]) uIsDlgformshow 1: "+uIsDlgformshow);
		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);

			const vElFocus = document.getElementById("inpnamakompetensi");
			if(!isMobile) vElFocus && vElFocus.focus();
		},200);
	},[uIsDlgformshow])
	React.useEffect(()=>{
		if(!uIsModalshow) { initModalawal(); return }
	},[uIsModalshow])
	React.useEffect(()=>{
		if(!uISModalhapus) return;
		setModalhapus(false);

		apiProsesselect();
	},[uISModalhapus])
	React.useEffect(()=>{
		if(!uISTerapimobiledone) return;

		setTerapimobiledone(false);
		apiProsesunselect();
	},[uISTerapimobiledone])
	//console.log("Kompetensi - uPageActive => "+JSON.stringify(uPageActive));

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard">
			<CCardHeader>
			<b>{uBahasaObj.caption_page500||"Page 500"}</b>
			</CCardHeader>

			<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	return (
		<>
		<CCard className="classbgcard">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<b>{uBahasaObj.caption_listdata||"List Data"}</b>
			
			<div>
				<small className="d-md-none text-primary">[{UFunc.formatAngka(uJmlData)}]</small>
				<span className="d-md-none mx-1">&middot;</span>
				<span className="text-muted classfontsmaller">
					<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
				</span>
			</div>
			</CCardHeader>

			<CCardBody className="px-0 px-lg-5">{contentMain()}</CCardBody>

			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination 
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i)=>setPageActive(i)}
					align="start"/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogform
			options={{centered:true}}
			onSimpan={()=>hdlKlikDlgsimpan()}
			show={uIsDlgformshow} 
			dialogHeader={uDlgformheader}
			toggle={hdlToggleDlgform}
			renderContent={contentDlgform()}/>

		<MyDialogview
			options={{size:"lg",centered:false}}
			show={uIsDlgviewshow} 
			dialogHeader={uDlgviewheader}
			toggle={hdlToggleDlgview}
			renderContent={contentDlgview()}/>

		<CModal fullscreen={true} visible={uIsModalshow}>
			<CModalHeader closeButton={false} 
				className="justify-content-start align-items-center classheadertop">
				<CLink 
					onClick={()=>setModalshow(false)}
					className="classcursorpointer">
				<CIcon icon="cilArrowCircleLeft" className="me-2 align-middle" height={30}/>
				</CLink>

				<CModalTitle>{uModalheader}</CModalTitle>
			</CModalHeader>

			<CModalBody>{contentModal()}</CModalBody>
		</CModal>
		</>
	)
}	

export default Kompetensi