import React from 'react'
import { 
	CButton,
} from '@coreui/react'
import CIcon from '@coreui/icons-react';
import PropTypes from "prop-types";

const MyButton = (props) => {
	const {centered,iconname,children,className,...rest} = props

	const vISpxexist	= (className||"").includes("px-");
	
	//console.log("MyButton - props => "+JSON.stringify(props))	
	return (
		<CButton className={(centered?"mx-auto ":"")+"classtombol "+(vISpxexist?"":"px-4 ")+(className||"")}
			{...rest}>
			{(iconname||"")!=="" && (
				<CIcon icon={iconname} className="align-middle me-1" height={20}/>
			)}
			<>{children||"Label"}</>
		</CButton>
	)
}
MyButton.propTypes = {
	centered: PropTypes.bool,
	iconname: PropTypes.string,
}
MyButton.defaultProps = { 
	centered: false 
}

export default MyButton