import React from 'react'
//import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CImage,
	CForm,
	CButton,
	CFormInput,
	CInputGroup,
	CLink,
	CTooltip,
	CFormSelect,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { Konfirm } from '../../helpers/onConfirm'
//import { UFunc } from '../../helpers/functions'
import { isMobile } from "react-device-detect";

const pjson 	= require('../../../package.json')
const Headersubstatuspasien = (props) => {
	//const { setToken,setLoading, showToast } = props 

	//--DOM--/
	//const uNavigate 			= useNavigate()
	const uDispatch 			= useDispatch()
	//--END DOM--/

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction)
	const uBahasaObj  			= useSelector(state => state.listBahasa)
	const uActiveroute			= useSelector(state => state.activeRoute)
	const uEfekapp				= useSelector(state => state.effectApp);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uDatacabangArr		= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const uCabangfilterArr		= uTokenObj.userhak==="FREEROLE" ? uDatacabangArr
		: uDatacabangArr.filter(vItems=>(uTokenObj.user_cabangarr || []).includes(vItems.id.toString()))
	
	//const uISTambahallowed 		= ((uTokenObj.userhak||"")==="FREEROLE" || (uTokenObj.userhak||"")==="ADMIN");
	const vFilterviewObj			= uKeywordObj.filter_statuskunjungan || {};
	const [uInpCabangid,setInpCabangid]	= React.useState(vFilterviewObj.cabang_id||uTokenObj.user_cabangid);
	const [uInpkeyword,setInpkeyword]= React.useState(vFilterviewObj.kwd_statuspasien||"");

	const hdlKeydown = (_EV) => {
		if(_EV.which===13) {
			_EV.stopPropagation();
			_EV.preventDefault();
			document.getElementById("btnCari") && 
				document.getElementById("btnCari").click();
		}//-*/
	}
	const hdlKlikCari = () => {
		//---SAVE_KEYWORD--/
		vFilterviewObj.cabang_id 			= uInpCabangid; 
		vFilterviewObj.kwd_statuspasien 	= uInpkeyword.trim(); 
		
		uKeywordObj.filter_statuskunjungan = vFilterviewObj;
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset = async() => {
		const vProses = () => {
			//---CLEAR_KEYWORD--/
	    	setInpCabangid(uTokenObj.user_cabangid); 
	    	setInpkeyword("");
	    	
			uKeywordObj.filter_statuskunjungan = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
			//---END CLEAR_KEYWORD--/
			
			//setHeaderView(true);
			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		if((uInpkeyword.trim() !== "")
			|| (uInpCabangid.toString() !== (uTokenObj.user_cabangid||"0").toString())
			) {
			if(await Konfirm(uBahasaObj.confirm_reset||"Apakah Yakin Akan Direset ?")) vProses();
		} else vProses();	
	}

	//console.log("Headersubstatuspasien - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
		<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
			className="me-2"
			height={30}/>
		{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||""}
		</div>

		<CForm>
			<CInputGroup 
				style={{minWidth:295,width:"auto"}} 
				size="sm">
			{uDatacabangArr.length > 1 && (
			<>
			{(uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") && (
				<>
				<CFormSelect size="sm"
					className="d-none d-md-inline"
					value={uInpCabangid} 
					onChange={(e) => setInpCabangid(e.target.value)} 
					id="inpcabangid">
					{uCabangfilterArr.map((vItems,vKeys)=>{
						return (<option value={vItems.id} key={vKeys}>{vItems.cabang_nama.toUpperCase()}</option>)
					})}
				</CFormSelect>

				<CDropdown className="d-md-none">
					<CDropdownToggle caret={true} className="px-1 border fw-bolder" color="light">
						{(uDatacabangArr.filter(vItems => 
							parseInt(vItems.id) === parseInt(uInpCabangid)
							)[0].cabang_kode || "").toUpperCase()
						}
					</CDropdownToggle>
					<CDropdownMenu>
						{uCabangfilterArr.map((vItems,vKeys)=>{
							return (
							<CDropdownItem onClick={()=>setInpCabangid(vItems.id)} key={vKeys} className="classcursorpointer">
								{vItems.cabang_kode.toUpperCase()}
								<span className="mx-1">-</span>
								{vItems.cabang_nama.toUpperCase()}
							</CDropdownItem>
							)
						})}
					</CDropdownMenu>
				</CDropdown>

				<span className="ms-1"/>
				</>
			)}
			</>
			)}
			<CFormInput size="sm" 
				className="w-50"
				value={uInpkeyword} 
				onChange={(e) => setInpkeyword(e.target.value)} 
				onKeyDown={(e) => hdlKeydown(e)} 
				placeholder={"--"+(uBahasaObj.petunjuk_keyword||"Masukkan uKeyword")+".."} 
				id="inpkeyword"/>
			<CButton size="sm" color="light" 
				className="border"
				onClick={()=>hdlKlikCari()} 
				id="btnCari">
				<CIcon icon="cilMagnifyingGlass"/>
			</CButton>
			</CInputGroup>
		</CForm>

		<div className="ms-1 d-none d-md-block">
			<CTooltip className="d-none d-md-block" content={"--"+(uBahasaObj.caption_resetdata||"Reset View Tampilan")}>
				<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer" />
			</CTooltip>

			<span className="mx-1">&middot;</span>

			(<b id="idjmldata" className="text-primary">0</b> {uBahasaObj.word_data||"Data"})
		</div>
		</>
	)//>
}

export default Headersubstatuspasien
