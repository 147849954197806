import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,CFormInput,CFormSelect,CInputGroup,CInputGroupText,CFormCheck,CButton,
		CFormRange,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,CDropdownDivider,
	CFormTextarea,
	CBadge,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogform,
	MyDialogview,
	MyDialogpasien,
	MyButton,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc,cBulanPendek,cAngkaTgl } from '../helpers/functions'
import useSatusehat from '../helpers/useSatusehat'

const pjson 		= require('../../package.json')
const Statuspasien	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const { loadToken } 		= useSatusehat(props);
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uConfigappsObj			= useSelector(state => state.uConfigappsObj) || {}; 
	const uSystemsettingObj			= useSelector(state => state.gSystemsetting)
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 25) : 100;
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uISDemoapp				= uConfigappsObj.level_app === "DEMO";
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	const vFilterviewObj			= uKeywordObj.filter_statuskunjungan || {};
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--VIEWS_VARS--/
	const uDatacabangArr			= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const uTokensatusehatObj			= JSON.parse(localStorage.getItem("tokensatusehat") || "{}");
	const [uEfekupdate,setEfekupdate] 	= React.useState(false);
	const [uThnlahirArr,setThnlahirArr]	= React.useState([]);
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);
	//--END VIEWS_VARS--/

	//--INIT_SELECTED_BEFORE--/
	const uLastinitObj 					= useSelector(state => state.gInitTabelSelected);// || {};
	const [uInitselectedObj,setInitselectedObj] = React.useState({});
	//--END INIT_SELECTED_BEFORE--/

	//--DLGFORM--/
	const [uISDlgformshow,setDlgformshow]		= React.useState(false);
	const [uDlgformdataObj,setDlgformdataObj]	= React.useState({});
	//--END DLGFORM--/

	//--DLGVIEW--/
	const [uISDlgviewshow,setDlgviewshow]		= React.useState(false);
	const [uDlgviewdataObj,setDlgviewdataObj]	= React.useState({});
	//--END DLGVIEW--/

	//--DLGPASIEN--/
	const [uISDlgpasienshow,setDlgpasienshow]	= React.useState(false);
	const [uDlgPasienID,setDlgPasienID]			= React.useState(0);
	//--END DLGPASIEN--/

	//--DOM_VARS--/
	const uElJmlData			= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlKlikPasienihs = (_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vIdxRow);

		const vCaptionUpper = (uDatamainArr[vArridx].caption_pasien||"Undf Pasien").toUpperCase();
		uDlgformdataObj.dlg_header 	= "Register IHS: "+vCaptionUpper;
		uDlgformdataObj.dlg_jenis 	= "REGISTERIHS";
		
		uDlgformdataObj.tabelid 	= _IDTABEL;//-->id_progress
		uDlgformdataObj.nik 		= uDatamainArr[vArridx].nik_pasien||"";
		uDlgformdataObj.tempat_lahir= uDatamainArr[vArridx].tempatlahir_pasien||"";

		const vTgllahir		= uDatamainArr[vArridx].tgllahir_pasien;
		const vTgllahirDT	= UFunc.toDate(vTgllahir);

		uDlgformdataObj.tgl = vTgllahir ? UFunc.leadZero(vTgllahirDT.getDate()) : "0";
		uDlgformdataObj.bln = vTgllahir ? UFunc.leadZero((vTgllahirDT.getMonth()+1)) : "0";
		uDlgformdataObj.thn = vTgllahir ? UFunc.leadZero(vTgllahirDT.getFullYear()) : "0";

		setDlgformshow(true);
	}
	const hdlKlikLihatpasien = (_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vIdxRow);

		setDlgPasienID(uDatamainArr[vArridx].id_pasien);
		setDlgpasienshow(true);
	}
	const hdlKlikScreening = (_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vIdxRow);

		const vCaptionUpper = (uDatamainArr[vArridx].caption_pasien||"Undf Pasien").toUpperCase();
		const vTglnow		= UFunc.DbDate();

		const vCabangID 	= parseInt(vFilterviewObj.cabang_id || uTokenObj.user_cabangid);
		const vCabangIDsession= parseInt(uTokenObj.user_cabangid);

		if(((uDatamainArr[vArridx].status_name||"HADIR")==="SELESAI"
			&& (uDatamainArr[vArridx].satusehat_status||"BELUM")==="SUDAH")
			|| (vTglnow !== (uDatamainArr[vArridx].tgl_kunjungan||"") || vCabangID !== vCabangIDsession)
			) {
			uDlgviewdataObj.dlg_header 	= "Screening Awal: "+vCaptionUpper;
			uDlgviewdataObj.dlg_jenis 	= "SCREENING";

			uDlgviewdataObj.screening_str 	= uDatamainArr[vArridx].screening_str || "";

			setDlgviewshow(true);
			return;
		}

		//console.log("uDatamainArr["+vArridx+"].satusehat_status = "+uDatamainArr[vArridx].satusehat_status); 

		uDlgformdataObj.tabelid 	= _IDTABEL;//-->id_progress
		uDlgformdataObj.dlg_header 	= "Screening Awal: "+vCaptionUpper;
		uDlgformdataObj.dlg_jenis 	= "SCREENING";
		uDlgformdataObj.dlg_size 	= "lg";

		const vScreeningObj 	= JSON.parse(uDatamainArr[vArridx].screening_str || "{}");

		uDlgformdataObj.tekanandarahatas 	= vScreeningObj.tekanandarahatas || 120;
		uDlgformdataObj.tekanandarahbawah 	= vScreeningObj.tekanandarahbawah || 80;
		uDlgformdataObj.suhu 				= vScreeningObj.suhu || 36;
		uDlgformdataObj.denyutnadi 			= vScreeningObj.denyutnadi || 75;
		uDlgformdataObj.berat 				= vScreeningObj.berat;

		uDlgformdataObj.nyeri_status 		= vScreeningObj.nyeri_status;
		uDlgformdataObj.nyeri_skala 		= vScreeningObj.nyeri_skala || 0;
		uDlgformdataObj.nyeri_kategori 		= vScreeningObj.nyeri_kategori;

		uDlgformdataObj.mental_emosi 		= vScreeningObj.mental_emosi;
		uDlgformdataObj.mental_konsepdiri	= vScreeningObj.mental_konsepdiri;
		uDlgformdataObj.mental_komunikasi 	= vScreeningObj.mental_komunikasi;

		uDlgformdataObj.psiko_emosi 		= vScreeningObj.psiko_emosi;
		uDlgformdataObj.psiko_mental		= vScreeningObj.psiko_mental;

		uDlgformdataObj.sosial_agama 		= vScreeningObj.sosial_agama;
		uDlgformdataObj.sosial_pendidikan	= vScreeningObj.sosial_pendidikan;
		uDlgformdataObj.sosial_pekerjaan 	= vScreeningObj.sosial_pekerjaan;

		uDlgformdataObj.riwayat_kesehatan 	= vScreeningObj.riwayat_kesehatan;
		uDlgformdataObj.riwayat_obat		= vScreeningObj.riwayat_obat;
		uDlgformdataObj.riwayat_gizi 		= vScreeningObj.riwayat_gizi;

		setDlgformshow(true);
	}
	const hdlKlikBatalhadir = async(_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vIdxRow);

		const vCaptionUpper = (uDatamainArr[vArridx].caption_pasien||"Undf Pasien").toUpperCase();
		
		if(await(Konfirm("Pembatalan Kehadiran: <b>"+vCaptionUpper+"</b>.."+
			"<br/>Apakah Anda Yakin ?"+
			""))) apiBatalhadir(_IDTABEL);
	}
	const hdlKlikBatalperiksa = async(_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vIdxRow);

		const vCaptionUpper = (uDatamainArr[vArridx].caption_pasien||"Undf Pasien").toUpperCase();

		if(await(Konfirm("Batalkan Status Periksa: <b>"+vCaptionUpper+"</b>"+
			"<br/>Apakah Anda Yakin ?"+
			""))) apiBatalperiksa(_IDTABEL);
	}
	const hdlKlikViewkunjungan = (_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vIdxRow);

		const vCaptionUpper = (uDatamainArr[vArridx].caption_pasien||"Undf Pasien").toUpperCase();
		const vRMitemArr	= JSON.parse(uDatamainArr[vArridx].rmitem_str || "[]");
		uDlgviewdataObj.tabelid 	= _IDTABEL;

		if(vRMitemArr.length > 0) {
			uDlgviewdataObj.dlg_header 	= "Data Kunjungan: "+vCaptionUpper;
			uDlgviewdataObj.dlg_jenis 	= "KUNJUNGAN";

			setDlgviewshow(true);
			return;
		}

		apiLoadkunjungan();
	}
	const hdlKlikViewsatusehat = (_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vIdxRow);

		const vCaptionUpper = (uDatamainArr[vArridx].caption_pasien||"Undf Pasien").toUpperCase();
		uDlgviewdataObj.tabelid = _IDTABEL;

		if((uDatamainArr[vArridx].satusehat_str||"") !== "") {
			uDlgviewdataObj.dlg_header 	= "Response Satusehat: "+vCaptionUpper;
			uDlgviewdataObj.dlg_jenis 	= "SATUSEHAT";

			setDlgviewshow(true); return;
		}

		apiLoadsatusehat();
	}
	const hdlKlikProsesnext = (_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vStatusteks = uDatamainArr[vArridx].status_name || "HADIR";

		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vIdxRow);

		if(vStatusteks==="HADIR" || vStatusteks==="PERIKSA") {
			apiCheckingperiksa(_IDTABEL);
			/*uDatamainArr[vArridx].is_processing = true;
			uNavigate("/subpasien/pasien/rekammedis/"+uDatamainArr[vArridx].id_pasien);//-*/
			return;
		}
		if(vStatusteks==="KASIR") {
			const vTmpObj	= { initID:_IDTABEL, initPage:uPageActive }
			uDispatch({type: "set", gInitTabelSelected: vTmpObj})

			uDatamainArr[vArridx].is_processing = true;
			uNavigate("/subbilling/kasir/bayar/"+uDatamainArr[vArridx].id_rekammedis);
			return;
		}
	}
	const hdlKlikKirimsatusehat = async(_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vIdxRow);

		//--VALIDASI_IHSNUMBER--/
		if((uDatamainArr[vArridx].ihs_pasien||"")==="") {
			showToast("<b>Pasien belum Memiliki IHS Number</b>.."+
				"<br/><br/>Silahkan Klik Tombol REGISTER IHS PASIEN !"+
				""); return;
		}
		if((uDatamainArr[vArridx].dokter_ihs||"")==="") {
			showToast("<b>Dokter belum Memiliki IHS Number</b>.."+
				"<p>Silahkan GET IHS Number untuk Dokter Melalui Menu: Master Data >> Data Dokter >> <b>Edit Data</b> !</p>"+
				""); return;
		}
		//--END VALIDASI_IHSNUMBER--/

		if(await Konfirm("<b>Kirim Data ke Server SATUSEHAT</b>.."+
			"<small class='fst-italic'>"+
			"<p>Data Pemeriksaan Pasien Akan Dikirim ke SATUSEHAT. Proses Ini Hanya Dapat Dilakukan SEKALI.</p>"+
			"<p>Proses Tidak Dapat DIULANGI ataupun DIBATALKAN.</p>"+
			"</small>"+
			"Apakah Anda Yakin ?"+
			"")) apiProsessatusehat(_IDTABEL);
	}
	const hdlKlikDlgsimpan = () => {
		if(uDlgformdataObj.dlg_jenis === "REGISTERIHS") { prosesRegisterihs(); return; }
		if(uDlgformdataObj.dlg_jenis === "SCREENING") { prosesScreening(); return; }
	}
	//--END HANDEL--/

	//--INIT_FUNC--/
	const prosesRegisterihs = () => {
		//--VALIDASI--/
		let vElfocus = document.getElementById("inpnik");
		if((uDlgformdataObj.nik||"").trim() === "") {
			vElfocus && vElfocus.focus();
			showToast("NIK Harus Diisi !"); return;
		}
		const vNotallowedstr 	= /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
		const vISnotallowed		= vNotallowedstr.test(uDlgformdataObj.nik||"");
		if(vISnotallowed) {
			vElfocus && vElfocus.focus();
			showToast("<b>NIK Tidak Valid</b>.."+
				"<br/>NIK Kemungkinan Menggunakan Karakter Yang TIDAK Diijinkan !"+
				""); return;
		}

		const vThnlahir		= UFunc.leadZero(uDlgformdataObj.thn,4).toString();
		const vBlnlahir		= UFunc.leadZero(uDlgformdataObj.bln,2).toString();
		const vTgllahir		= UFunc.leadZero(uDlgformdataObj.tgl,2).toString();
		const vTanggallahir = vThnlahir+"-"+vBlnlahir+"-"+vTgllahir+
				"";
		const vTanggallahirDT	= UFunc.toDate(vTanggallahir);

		vElfocus = document.getElementById("inplahirtgl");
		if((vTanggallahirDT.getDate()) !== parseInt(vTgllahir)) {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Lahir Tidak Valid !"); return;
		}

		vElfocus = document.getElementById("inplahirbln");
		if((vTanggallahirDT.getMonth()+1) !== parseInt(vBlnlahir)) {
			vElfocus && vElfocus.focus();
			//showToast("Bulan Lahir Tidak Valid ! "+(vTanggallahirDT.getMonth()+1)+" "+parseInt(vBlnlahir)); return;
			showToast("Bulan Lahir Tidak Valid ! "); return;
		}

		vElfocus = document.getElementById("inplahirthn");
		if(vTanggallahirDT.getFullYear() !== parseInt(vThnlahir)) {
			vElfocus && vElfocus.focus();
			showToast("Tahun Lahir Tidak Valid !"); return;
		}
		//--END VALIDASI--/

		apiProsesihs();
	}
	const prosesScreening = () => {
		//--VALIDASI--/
		//--END VALIDASI--/

		apiProsesscreening();
	}
	//--END INIT_FUNC--/

	//--CONTENT_FUNC--/
	const contentMain = () => {
		if(uHtml500msg) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= vMin;
		const vKeyword 		= UFunc.filterRegex(vFilterviewObj.kwd_statuspasien || "");
		const vKeywordcolor = uSettingObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vTglnow		= UFunc.DbDate();
		/*const vDataviewArr	= isMobile 
			? uDatamainArr.slice(0,(vMax+1)) : uDatamainArr.slice(vMin,(vMax+1));//--*/
		const vDataviewArr	= uDatamainArr.filter(vItems => 
			parseInt(vItems.page||1) === parseInt(uPageActive));


		const vCabangID 	= parseInt(vFilterviewObj.cabang_id || uTokenObj.user_cabangid);
		const vCabangIDsession= parseInt(uTokenObj.user_cabangid);
		
		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width={35} className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="2" className="text-start">{uBahasaObj.caption_tglkunjungan||"Tgl Kunjungan"}</CCol>
			<CCol md="3" lg="4" className="text-start">{uBahasaObj.caption_namapasien||"Nama Pasien"}</CCol>
			<CCol md="3" lg="2" className="text-start">{uBahasaObj.caption_dokterrujukan||"Dokter Rujukan"}</CCol>
			<CCol md="1" className="">
				<span>{uBahasaObj.word_status||"Status"}</span>
				<span className="ms-1 d-none d-lg-inline"><br/>{uBahasaObj.word_kunjungan||"Kunjungan"}</span>
			</CCol>
			<CCol md="1" className="">
				<span>{uBahasaObj.word_kirim||"Kirim"}</span>
				<span className="ms-1 d-none d-lg-inline"><br/>{uBahasaObj.word_satusehat||"Satusehat"}</span>
			</CCol>
			<CCol md="2" className="">&middot;</CCol>
			</CRow>
			</th>
			</tr>

			<tr className="d-md-none classfontsmaller">
				<th width={30} className="p-0"/>
				<th className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_kunjungan,id_pasien,caption_pasien,telp_pasien,ihs_pasien,
				dokter_id,dokter_caption,dokter_ihs,waktu_hadir,keterangan,
				waktu_periksa_start,waktu_periksa_end,id_rekammedis,waktu_selesai,
				encounter_uid,satusehat_status,jenis_antrian,status_name,
				is_processing,id_antrian,no_ruang,
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (caption_pasien||"").toUpperCase();

			const vPasiencaption	= vKeyword==="" ? (caption_pasien||"UNDF NAMA") 
				: (caption_pasien||"UNDF NAMA").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vPasientelp	= vKeyword==="" ? (telp_pasien||"-") 
				: (telp_pasien||"-").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");
			const vDoktercaption	= vKeyword==="" ? UFunc.Usercaptionfilter(dokter_caption||"-") 
				: UFunc.Usercaptionfilter(dokter_caption||"-").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>");

			const vStatusteks		= status_name || "HADIR";
			let vNextprocessteks	= "Periksa";
			if(vStatusteks==="SELESAI") {
				vNextprocessteks= "Selesai";
			} else if(vStatusteks === "KASIR") {
				vNextprocessteks= "Bayar";
			}
			/*vStatusteks 	= "SELESAI";
			vNextprocessteks = "<img src='"+pjson.homepage+"api/images/icon_satusehat.png' height='20'/>";
			//-*/

			const vStatusHTML = () => {
				if(vStatusteks === "PERIKSA")
					return (
						<CBadge color="warning" className="py-2 classblinking">{vStatusteks}</CBadge>
					)//>
				if(vStatusteks === "KASIR")
					return (
						<CBadge color="danger" className="py-2">{vStatusteks}</CBadge>
					)//>
				if(vStatusteks === "SELESAI") 
					return (
						<CBadge color="success" className="py-2">{vStatusteks}</CBadge>
					)//>

				return (
					<CBadge color="primary" className="py-2">{vStatusteks}</CBadge>
				)//>
			}

			const vTanggalHTML = () => {
				if(vTglnow !== (tgl_kunjungan||"")) {
					if(vKeyword === "") return (UFunc.TglAngka(tgl_kunjungan))

					return (
						UFunc.renderHTML(
							UFunc.TglAngka(tgl_kunjungan).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></b>")
						)
					)
				}

				const vTglwaktuselesaiDT	= UFunc.toDatetime(tgl_kunjungan+" "+waktu_selesai);
				if(vStatusteks === "SELESAI") 
					return (
						<CBadge className="py-2" color="success">Hari Ini &middot; {UFunc.JamMenit(vTglwaktuselesaiDT)}</CBadge>
					)//>

				const vTglwaktuhadirDT		= UFunc.toDatetime(tgl_kunjungan+" "+waktu_hadir);
				return (
					<CBadge className="py-2" color="primary">Hari Ini &middot; {UFunc.JamMenit(vTglwaktuhadirDT)}</CBadge>
				)//>
			}
			const vTanggalmobile = () => {
				if(vTglnow !== (tgl_kunjungan||"")) return (UFunc.TglAngka(tgl_kunjungan));

				const vTglwaktuselesaiDT	= UFunc.toDatetime(tgl_kunjungan+" "+waktu_selesai);
				if(vStatusteks === "SELESAI") 
					return (
					<span className="text-success">Hari Ini &middot; {UFunc.JamMenit(vTglwaktuselesaiDT)}</span>
					)

				const vTglwaktuhadirDT		= UFunc.toDatetime(tgl_kunjungan+" "+waktu_hadir);
				return (
					<span className="text-primary">Hari Ini &middot; {UFunc.JamMenit(vTglwaktuhadirDT)}</span>
				)//>
			}

			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uRowselect===vKeys?" classrowselect":"")}>
				<td className="px-1 pe-0">{vNumber}.</td>

				<td className="px-1">
				<CRow className="mx-0">
				<CCol md="2" className="px-1 text-md-start d-none d-md-block">
					{vTanggalHTML()}

					{(parseInt(id_antrian)||0) <= 0 && (
						<CIcon icon="cilClone" className="ms-2 classikonbox text-danger border-danger" height={20}/>
					)}

					{((parseInt(no_ruang)||0) > 0 && vTglnow === (tgl_kunjungan||""))
					&& (
						<div className="classfontsmaller fw-bolder"><small>&middot; RG-{no_ruang} &middot;</small></div>
					)}

					<div className="d-none">{vTglnow}</div>
				</CCol>

				<CCol xs="12" md="3" lg="4" className="px-1 text-start">
					<div className="d-flex justify-content-between">
					<div className="text-info">
					{uTokenObj.userhak==="ENTRI" ? (
						<CLink onClick={()=>uNavigate("/subpasien/pasien/daftarantri/"+id_pasien)} 
							className="text-decoration-none classcursorpointer fw-bolder">
							{UFunc.renderHTML(vPasiencaption)}
						</CLink>
					) : (
						<>{UFunc.renderHTML(vPasiencaption)}</>
					)}

					{(ihs_pasien||"")==="" && (
					<CTooltip content="IHS Pasien Unavailable">
					<CIcon icon="cilAddressBook" className="d-md-none ms-2 classikonbox align-middle" height={25}/>
					</CTooltip>
					)}
					</div>

					<CDropdown className="d-md-none">
					<CDropdownToggle caret={false} size="sm" className="p-0" color="transparent">
						<CIcon icon="cilOptions" className="" height={18}/>
					</CDropdownToggle>

					<CDropdownMenu className="py-1">
					{((ihs_pasien||"")==="" 
						&& vCabangID === vCabangIDsession
					) && (
					<CDropdownItem onClick={()=>hdlKlikPasienihs(id)} className="bg-secondary classcursorpointer">
						<CIcon icon="cilAddressBook" className="classikonbox me-2" height={23}/>
						Register IHS Pasien
					</CDropdownItem>
					)}

					<CDropdownItem onClick={()=>hdlKlikLihatpasien(id)} className="classcursorpointer">
						<CIcon icon="cilFindInPage" className="classikonbox me-2" height={23}/>
						Lihat Data Pasien
					</CDropdownItem>

					{(uTokenObj.userhak === "FREEROLE"
						|| uTokenObj.userhak === "ADMIN"
						|| uTokenObj.userhak === "DOKTER"
					) && (
						<CDropdownItem onClick={()=>{ uNavigate("/subpasien/pasien/rekammedis/"+id_pasien); }} className="classcursorpointer">
							<CImage src={pjson.homepage+"api/images/icon_rekammedis.png"} className="align-middle mx-1 me-2" height={25}/>
							Lihat Rekam Medis
						</CDropdownItem>
					)}

					{(vStatusteks==="HADIR") && (
					<>
						{(vTglnow === (tgl_kunjungan||"") && vCabangID === vCabangIDsession
						) && (
						<>
						<CDropdownItem onClick={()=>hdlKlikScreening(id)} className="classcursorpointer">
							<CIcon icon="cilScreenSmartphone" className="classikonbox me-2" height={23}/>
							Screening Awal
						</CDropdownItem>

						<CDropdownDivider/>

						<CDropdownItem onClick={()=>hdlKlikBatalhadir(id)} className="classcursorpointer text-danger">
							<CIcon icon="cilBan" className="classikonbox me-2" height={23}/>
							BATAL Kehadiran
						</CDropdownItem>
						</>
						)}
					</>
					)}

					{(vStatusteks==="PERIKSA") && (
					<>
						{(vTglnow === (tgl_kunjungan||"") && vCabangID === vCabangIDsession
						) && (
						<>
						<CDropdownItem onClick={()=>hdlKlikScreening(id)} className="classcursorpointer">
							<CIcon icon="cilScreenSmartphone" className="classikonbox me-2" height={23}/>
							Screening Awal
						</CDropdownItem>

						<CDropdownDivider/>

						<CDropdownItem onClick={()=>hdlKlikBatalperiksa(id)} className="classcursorpointer">
							<CImage src={pjson.homepage+"api/images/icon_periksabatal.png"} className="align-middle mx-1" height={25}/>
							Batalkan PERIKSA
						</CDropdownItem>
						</>
						)}
					</>
					)}

					{(vStatusteks==="KASIR") && (
					<>
						{(vTglnow === (tgl_kunjungan||"") && vCabangID === vCabangIDsession
						) && (
						<>
						<CDropdownItem onClick={()=>hdlKlikScreening(id)} className="classcursorpointer">
							<CIcon icon="cilScreenSmartphone" className="classikonbox me-2" height={23}/>
							Edit Screening Awal
						</CDropdownItem>

						<CDropdownDivider/>
						</>
						)}

						<CDropdownItem onClick={()=>hdlKlikViewkunjungan(id)} className="classcursorpointer">
							<CImage src={pjson.homepage+"api/images/menus_statuspasien.png"} className="align-middle mx-1" height={25}/>
							Lihat Kunjungan
						</CDropdownItem>
					</>
					)}

					{(vStatusteks==="SELESAI") && (
					<>
						{(vTglnow === (tgl_kunjungan||"") && vCabangID === vCabangIDsession
						) && (
						<>
						<CDropdownItem onClick={()=>hdlKlikScreening(id)} className="classcursorpointer">
							<CIcon icon="cilScreenSmartphone" className="classikonbox me-2" height={23}/>
							{(satusehat_status||"BELUM")!=="SUDAH" ? (
							"Edit Screening Awal"
							) : (
							"LIHAT Screening Awal"
							)}
						</CDropdownItem>

						</>
						)}

						<CDropdownDivider/>

						<CDropdownItem onClick={()=>hdlKlikViewkunjungan(id)} className="classcursorpointer">
							<CImage src={pjson.homepage+"api/images/menus_statuspasien.png"} className="align-middle mx-1" height={25}/>
							Lihat Kunjungan
						</CDropdownItem>

						{(satusehat_status||"BELUM")==="SUDAH" && (
						<>
						<CDropdownItem onClick={()=>hdlKlikViewsatusehat(id)} className="classcursorpointer text-success">
							<CImage src={pjson.homepage+"api/images/icon_satusehat.png"} className="align-middle mx-1" height={25}/>
							Status Satusehat
						</CDropdownItem>
						</>
						)}
					</>
					)}

					</CDropdownMenu>
					</CDropdown>
					</div>

					{uTokenObj.userhak!=="DOKTER" && (
					<div className="classfontsmaller d-none d-md-block"><small>Telp/HP. <b className="">{UFunc.renderHTML(vPasientelp)}</b></small></div>
					)}

					{(ihs_pasien||"")==="" && (
					<>
						{vCabangID === vCabangIDsession ? (
						<>
							<div className="my-2 d-none d-lg-block">
							<CButton size="sm" shape="rounded-3" color="secondary"
								onClick={()=>hdlKlikPasienihs(id)}
								className="text-white px-3" 
								style={{backgroundImage: "linear-gradient(#DCDCDC,gray)",}}
								id={"btnIhspasien"+id}>
								<small>
								<CIcon icon="cilAddressBook" className="me-2"/>
								Register IHS Pasien
								</small>
							</CButton>
							</div>
						
							<div className="d-none d-md-block d-lg-none classfontsmaller">
								<div className="classborderbottom my-1"/>
								<small className="text-danger fst-italic">&middot; IHS Pasien Unavailable &middot;</small>
							</div>
						</>
						) : (
						<>
							<div className="classborderbottom my-1 d-none d-md-block"/>

							<div className="classfontsmaller d-none d-md-block">
							<small className="text-danger fst-italic">&middot; IHS Pasien Unavailable &middot;</small>
							</div>
						</>
						)}
					</>
					)}

					{(keterangan||"")!=="" && (
					<>
					<div className="classborderbottom my-1 d-none d-md-block"/>
					<div className="classpetunjuk px-0 text-primary">{keterangan}</div>
					</>
					)}
				</CCol>

				<CCol xs="12" className="px-1 d-md-none classborderbottom my-3"/>

				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.word_tanggal||"Tanggal"}</CCol>
				<CCol xs="7" className="px-1 d-md-none">
					{(parseInt(id_antrian)||0) <= 0 && (
					<span className="d-md-none align-top">
						<CIcon icon="cilClone" className="mx-auto classikonbox text-danger border-danger" height={18}/>
						<span className="mx-1">&middot;</span>
					</span>
					)}

					{vTanggalmobile()}
				</CCol>

				{uTokenObj.userhak!=="DOKTER" && (
				<>
				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.cpation_telppasien||"Telp/HP Pasien"}</CCol>
				<CCol xs="7" className="px-1 d-md-none"><b>{UFunc.renderHTML(vPasientelp)}</b></CCol>
				</>
				)}

				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_dokter||"Dokter"}</CCol>
				<CCol xs="8" md="3" lg="2" className="px-1 text-md-start">
					<div className="fw-bolder d-none d-lg-block">{UFunc.renderHTML(vDoktercaption)}</div>
					<div className="classfontsmaller d-none d-md-block d-lg-none">{UFunc.renderHTML(vDoktercaption)}</div>

					{(dokter_ihs||"")==="" ? (
					<>
						<div className="fw-bolder d-md-none text-danger">{UFunc.renderHTML(vDoktercaption)}</div>
						<div className="classborderbottom my-1 d-none d-md-block"/>

						<div className="classfontsmaller d-none d-md-block">
						<small className="text-danger fst-italic">&middot; IHS Dokter Unavailable &middot;</small>
						</div>
					</>
					) : (
						<div className="fw-bolder d-md-none">{UFunc.renderHTML(vDoktercaption)}</div>
					)}
				</CCol>

				<CCol xs="12" className="px-1 d-md-none classborderbottom my-3"/>

				<CCol xs="6" className="px-1 text-start d-md-none">{uBahasaObj.caption_statuskunjungan||"Status Kunjungan"}</CCol>
				<CCol xs="6" className="px-1 d-md-none">{(()=>{
					let vColor = "primary";
					if(vStatusteks === "PERIKSA")
						vColor = "warning";
					else if(vStatusteks === "KASIR")
						vColor = "danger";
					else if(vStatusteks === "SELESAI")
						vColor = "success";
			
					return (<b className={"text-"+vColor}>{vStatusteks}</b>)
				})()}</CCol>

				<CCol md="1" className="px-1 px-md-0 text-md-center d-none d-md-block">{vStatusHTML()}</CCol>
				
				<CCol xs="6" className="px-1 text-start d-md-none">{uBahasaObj.caption_kirimsatusehat||"Kirim Satusehat"}</CCol>
				<CCol xs="6" className="px-1 d-md-none">{(()=>{
					if((satusehat_status||"BELUM")!=="SUDAH" && (encounter_uid||"")==="") return (<i>&middot;NONE&middot;</i>);
					
					if((satusehat_status||"BELUM")!=="SUDAH" && (encounter_uid||"")!=="") return (
						<span className="text-danger">Encounter</span>
					);

					return (<span className="text-success">LENGKAP</span>)
				})()}</CCol>
				
				<CCol md="1" className="px-1 px-md-0 text-center d-none d-md-block">
					{(encounter_uid||"")==="" ? (<>&middot;</>) : (
					<>
						{(satusehat_status||"BELUM")==="SUDAH" ? (
							<CIcon icon="cilCheckCircle" height={23} className="text-success"/>
						) : (
							<CIcon icon="cilCheckCircle" height={23} className="text-danger"/>
						)}
					</>
					)}
				</CCol>

				<CCol xs="12" className="px-1 d-md-none classborderbottom my-3"/>

				<CCol xs="12" md="2" className="px-0 px-lg-1 text-center text-md-start ">
					{(is_processing||false)=== true ? (
						<center><CSpinner color="primary" size="sm"/></center>
					) : (
					<div className="d-flex justify-content-center justify-content-md-between">
					<div className="text-center d-none d-md-block px-0">
					<CDropdown className="px-0 mx-0">
						<CDropdownToggle caret={false} size="sm" className="p-0 mx-0" color="transparent">
							<CIcon icon="cilChevronBottom" className="classikonbox" height={25}/>
						</CDropdownToggle>

						<CDropdownMenu className="py-1">
						{((ihs_pasien||"") === "" 
							&& vCabangID === vCabangIDsession
						) && (
						<CDropdownItem onClick={()=>hdlKlikPasienihs(id)} className="d-lg-none bg-secondary classcursorpointer">
							<CIcon icon="cilAddressBook" className="classikonbox me-2" height={23}/>
							Register IHS Pasien
						</CDropdownItem>
						)}
						<CDropdownItem onClick={()=>hdlKlikLihatpasien(id)} className="classcursorpointer">
							<CIcon icon="cilFindInPage" className="classikonbox me-2" height={23}/>
							Lihat Data Pasien
						</CDropdownItem>

						{(uTokenObj.userhak === "FREEROLE"
							|| uTokenObj.userhak === "ADMIN"
							|| uTokenObj.userhak === "DOKTER"
						) && (
						<CDropdownItem onClick={()=>{ uNavigate("/subpasien/pasien/rekammedis/"+id_pasien); }} className="classcursorpointer">
							<CImage src={pjson.homepage+"api/images/icon_rekammedis.png"} className="align-middle mx-1 me-2" height={25}/>
							Lihat Rekam Medis
						</CDropdownItem>
						)}

						{(vStatusteks==="HADIR") && (
						<>
							{(vTglnow === (tgl_kunjungan||"") && vCabangID === vCabangIDsession
							) && (
							<>
							<CDropdownItem onClick={()=>hdlKlikScreening(id)} className="classcursorpointer">
								<CIcon icon="cilScreenSmartphone" className="classikonbox me-2" height={23}/>
								Screening Awal
							</CDropdownItem>
							<CDropdownDivider/>

							<CDropdownItem onClick={()=>hdlKlikBatalhadir(id)} className="classcursorpointer text-danger">
								<CIcon icon="cilBan" className="classikonbox me-2" height={23}/>
								BATAL Kehadiran
							</CDropdownItem>
							</>
							)}
						</>
						)}

						{(vStatusteks==="PERIKSA") && (
						<>
							{(vTglnow === (tgl_kunjungan||"") && vCabangID === vCabangIDsession
							) && (
							<>
							<CDropdownItem onClick={()=>hdlKlikScreening(id)} className="classcursorpointer">
								<CIcon icon="cilScreenSmartphone" className="classikonbox me-2" height={23}/>
								Screening Awal
							</CDropdownItem>

							<CDropdownDivider/>

							<CDropdownItem onClick={()=>hdlKlikBatalperiksa(id)} className="classcursorpointer">
								<CImage src={pjson.homepage+"api/images/icon_periksabatal.png"} className="align-middle mx-1" height={25}/>
								Batalkan PERIKSA
							</CDropdownItem>
							</>
							)}
						</>
						)}

						{(vStatusteks==="KASIR") && (
						<>
							{(vTglnow === (tgl_kunjungan||"") && vCabangID === vCabangIDsession
							) && (
							<>
							<CDropdownItem onClick={()=>hdlKlikScreening(id)} className="classcursorpointer">
								<CIcon icon="cilScreenSmartphone" className="classikonbox me-2" height={23}/>
								Edit Screening Awal
							</CDropdownItem>

							<CDropdownDivider/>
							</>
							)}

							<CDropdownItem onClick={()=>hdlKlikViewkunjungan(id)} className="classcursorpointer">
								<CImage src={pjson.homepage+"api/images/menus_statuspasien.png"} className="align-middle mx-1" height={25}/>
								Lihat Kunjungan
							</CDropdownItem>
						</>
						)}

						{(vStatusteks==="SELESAI") && (
						<>
						{(vTglnow === (tgl_kunjungan||"") && vCabangID === vCabangIDsession
						) && (
						<>
						<CDropdownItem onClick={()=>hdlKlikScreening(id)} className="classcursorpointer">
							<CIcon icon="cilScreenSmartphone" className="classikonbox me-2" height={23}/>
							{(satusehat_status||"BELUM")!=="SUDAH" ? (
							"Edit Screening Awal"
							) : (
							"LIHAT Screening Awal"
							)}
						</CDropdownItem>

						</>
						)}

						<CDropdownDivider/>

						<CDropdownItem onClick={()=>hdlKlikViewkunjungan(id)} className="classcursorpointer">
							<CImage src={pjson.homepage+"api/images/menus_statuspasien.png"} className="align-middle mx-1" height={25}/>
							Lihat Kunjungan
						</CDropdownItem>

						{(satusehat_status||"BELUM")==="SUDAH" && (
						<>
						<CDropdownItem onClick={()=>hdlKlikViewsatusehat(id)} className="classcursorpointer text-success">
							<CImage src={pjson.homepage+"api/images/icon_satusehat.png"} className="align-middle mx-1" height={25}/>
							Status Satusehat
						</CDropdownItem>
						</>
						)}
						</>
						)}
						</CDropdownMenu>
					</CDropdown>
					</div>

					{(vCabangID === vCabangIDsession) && (
					<>
					{(vNextprocessteks!=="") && (
					<div className="text-center d-md-grid" style={{width:"calc(100% - 40px)"}}>
						{vStatusteks === "SELESAI" ? (
						<>
							{((satusehat_status||"BELUM")!=="SUDAH"
								&& (uTokenObj.userhak !== "DOKTER") 
							) && (
							<MyButton size="sm" onClick={()=>hdlKlikKirimsatusehat(id)} title="--Kirim ke SATUSEHAT" 
								className="classfontsmaller px-4 px-md-1 px-lg-3" 
								id="btnProses">
								<img src={pjson.homepage+"api/images/icon_satusehat.png"} className="align-middle me-lg-1" height={18}/>
								Kirim SS
								<CIcon icon="cilArrowRight" height={15} className="align-middle d-md-none d-lg-inline"/>
							</MyButton>
							)}
						</>
						) : (
						<>
							{(((vStatusteks === "KASIR" && uTokenObj.userhak === "ENTRI")
								|| (uTokenObj.userhak === "FREEROLE" || uTokenObj.userhak === "ADMIN") 
								|| ((vStatusteks === "HADIR" || vStatusteks === "PERIKSA")
									&& uTokenObj.userhak === "DOKTER") 
								) && vTglnow === (tgl_kunjungan||"") 
							) && (
							<MyButton size="sm" onClick={()=>hdlKlikProsesnext(id)} 
								className="classfontsmaller px-4 px-md-1 px-lg-3" 
								id="btnProses">
								{(vNextprocessteks)} 
							<CIcon icon="cilArrowRight" height={15} className="align-middle"/>
							</MyButton>
							)}
						</>
						)}
					</div>
					)}
					</>
					)}
					</div>
					)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgview = () => {
		if(!uISDlgviewshow) return (<></>)//>

		if(uDlgviewdataObj.dlg_jenis === "SCREENING") return contentViewscreening();
		if(uDlgviewdataObj.dlg_jenis === "KUNJUNGAN") return contentViewkunjungan();
		if(uDlgviewdataObj.dlg_jenis === "SATUSEHAT") return contentViewsatusehat();

		return (<></>)//>
	}
	const contentViewscreening = () => {
		if(uDlgviewdataObj.dlg_jenis !== "SCREENING") return (<></>)//>

		const vDataObj	= JSON.parse(uDlgviewdataObj.screening_str || "{}");

		return (
		<>
			<div className="py-2 px-3 mb-3 border-top border-bottom fw-bolder">
			Screening Medis
			</div>

			<CRow className="my-2 mx-lg-0">
				<CCol xs="4" className="text-md-end">
					<span className="d-none d-md-block">Tekanan Darah</span>
					<small className="d-md-none">Tekanan Darah</small>
				</CCol>
				<CCol xs="8" md="7" lg="4" className="">
					{vDataObj.tekanandarahatas || 0}
					<span className="mx-1">/</span>
					{vDataObj.tekanandarahbawah || 0}
				</CCol>
			</CRow>

			<CRow className="my-2 mx-lg-0">
				<CCol xs="4" className="text-md-end">
					<span className="d-none d-md-block">Denyut Nadi</span>
					<small className="d-md-none">Denyut</small>
				</CCol>
				<CCol xs="8" md="5" lg="4" className="">
					<b>{vDataObj.denyutnadi || 0}</b>
					<small className="ms-1">Detak / Menit</small>
				</CCol>
			</CRow>

			<CRow className="my-2 mx-lg-0">
				<CCol xs="4" className="text-md-end">
					<span className="d-none d-md-block">Suhu Badan</span>
					<small className="d-md-none">Suhu</small>
				</CCol>
				<CCol xs="8" md="3" lg="3" className="">
					<b>{vDataObj.suhu || 0}</b>
					<small>&deg; C</small>
				</CCol>
			</CRow>

			<CRow className="my-2 mx-lg-0">
				<CCol xs="4" className="text-md-end">
					<span className="d-none d-md-block">Berat Badan</span>
					<small className="d-md-none">Berat</small>
				</CCol>
				<CCol xs="8" md="3" lg="3" className="">
					<b>{vDataObj.berat || 0}</b>
					<small className="ms-1">KG</small>
				</CCol>
			</CRow>

			<div className="py-2 px-3 my-3 border-top border-bottom fw-bolder">
			KONDISI NYERI
			</div>

			<CRow className="my-2 mx-lg-0">
				<CCol xs="4" className="text-md-end">
					<span className="d-none d-md-block">Status Nyeri</span>
					<small className="d-md-none">Status Nyeri</small>
				</CCol>
				<CCol xs="8" className="">
					<b>{vDataObj.nyeri_status || "-"}</b>
				</CCol>
			</CRow>
			<CRow className="my-2 mx-lg-0">
				<CCol xs="4" className="text-md-end">
					<span className="d-none d-md-block">Skala Nyeri</span>
					<small className="d-md-none">Skala Nyeri</small>
				</CCol>
				<CCol className="">
					<b>{vDataObj.nyeri_skala || "-"}</b>
				</CCol>
			</CRow>

			<CRow className="my-2 mx-lg-0">
				<CCol xs="4" className="text-md-end">
					<span className="d-none d-md-block">Kategori</span>
					<small className="d-md-none">Kategori</small>
				</CCol>
				<CCol className="">
					<b>{vDataObj.nyeri_kategori || "-"}</b>
				</CCol>
			</CRow>

			<div className="py-2 px-3 my-3 border-top border-bottom fw-bolder">
			STATUS MENTAL
			</div>

			<CRow className="my-2 mx-lg-0">
				<CCol xs="4" className="text-md-end">
					<span className="d-none d-md-block">Emosi</span>
					<small className="d-md-none">Emosi</small>
				</CCol>
				<CCol className="">
					<span className="fst-italic">{vDataObj.mental_emosi || "-"}</span>
				</CCol>
			</CRow>

			<CRow className="my-2 mx-lg-0">
				<CCol xs="4" className="text-md-end">
					<span className="d-none d-md-block">Konsep Diri</span>
					<small className="d-md-none">Konsep Diri</small>
				</CCol>
				<CCol className="">
					<span className="fst-italic">{vDataObj.mental_konsepdiri || "-"}</span>
				</CCol>
			</CRow>

			<CRow className="my-2 mx-lg-0">
				<CCol xs="4" className="text-md-end">
					<span className="d-none d-md-block">Gaya Komunikasi</span>
					<small className="d-md-none">Komunikasi</small>
				</CCol>
				<CCol className="">
					<span className="fst-italic">{vDataObj.mental_komunikasi || "-"}</span>
				</CCol>
			</CRow>

			<div className="py-2 px-3 my-3 border-top border-bottom fw-bolder">
			STATUS PSIKOSOSIAL-SPIRITUAL
			</div>

			<CRow className="my-2 mx-lg-0">
				<CCol xs="4" className="text-md-end">
					<span className="d-none d-md-block">Status Mental</span>
					<small className="d-md-none">Status Mental</small>
				</CCol>
				<CCol xs="8" className="">
					<b>{vDataObj.psiko_mental || "-"}</b>
				</CCol>
			</CRow>

			<CRow className="my-2 mx-lg-0">
				<CCol xs="4" className="text-md-end">
					<span className="d-none d-md-block">Respon Emosi</span>
					<small className="d-md-none">Respon Emosi</small>
				</CCol>
				<CCol xs="8" className="">
					<b>{vDataObj.psiko_emosi || "-"}</b>
				</CCol>
			</CRow>

			<div className="py-2 px-3 my-3 border-top border-bottom fw-bolder">
			SOSIAL BUDAYA
			</div>

			<CRow className="my-2 mx-lg-0">
				<CCol xs="4" className="text-md-end">
					<span className="d-none d-md-block">Agama</span>
					<small className="d-md-none">Agama</small>
				</CCol>
				<CCol className="">
					<span className="fst-italic">{vDataObj.sosial_agama || "-"}</span>
				</CCol>
			</CRow>

			<CRow className="my-2 mx-lg-0">
				<CCol xs="4" className="text-md-end">
					<span className="d-none d-md-block">Pendidikan Terakhir</span>
					<small className="d-md-none">Pendidikan</small>
				</CCol>
				<CCol className="">
					<span className="fst-italic">{vDataObj.sosial_pendidikan || "-"}</span>
				</CCol>
			</CRow>

			<CRow className="my-2 mx-lg-0">
				<CCol xs="4" className="text-md-end">
					<span className="d-none d-md-block">Pekerjaan</span>
					<small className="d-md-none">Pekerjaan</small>
				</CCol>
				<CCol className="">
					<span className="fst-italic">{vDataObj.sosial_pekerjaan || "-"}</span>
				</CCol>
			</CRow>

			<div className="py-2 px-3 my-3 border-top border-bottom fw-bolder">
			LAINNYA
			</div>

			<CRow className="my-2 mx-lg-0">
				<CCol xs="4" className="text-md-end pe-0 pe-md-3">
					<span className="d-none d-md-block">Riwayat Kesehatan</span>
					<small className="d-md-none">Rwyt Kesehatan</small>
				</CCol>
				<CCol className="">
					<span className="fst-italic">{vDataObj.riwayat_kesehatan || "-"}</span>
				</CCol>
			</CRow>

			<CRow className="my-2 mx-lg-0">
				<CCol xs="4" className="text-md-end">
					<span className="d-none d-md-block">Riwayat <span className="me-1 d-none d-lg-inline">Penggunaan</span>Obat</span>
					<small className="d-md-none">Riwayat Obat</small>
				</CCol>
				<CCol className="">
					<span className="fst-italic">{vDataObj.riwayat_obat || "-"}</span>
				</CCol>
			</CRow>

			<CRow className="my-2 mx-lg-0">
			<CCol xs="4" className="text-md-end">
				<span className="d-none d-md-block">Screening Gizi</span>
				<small className="d-md-none">Screening Gizi</small>
			</CCol>
			<CCol className="">
				<span className="fst-italic">{vDataObj.riwayat_gizi || "-"}</span>
			</CCol>
		</CRow>
		</>
		)//>
	}
	const contentViewkunjungan = () => {
		if(uDlgviewdataObj.dlg_jenis !== "KUNJUNGAN") return (<></>)//>

		const vTabelID	= parseInt(uDlgviewdataObj.tabelid)||0;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,vTabelID);
		const vRMitemArr= JSON.parse(uDatamainArr[vArridx].rmitem_str || "[]");

		const vTglwaktuhadirDT	= UFunc.toDatetime(uDatamainArr[vArridx].tgl_kunjungan+" "+uDatamainArr[vArridx].waktu_hadir);
		const vTglwaktuperiksaDT= UFunc.toDatetime(uDatamainArr[vArridx].tgl_kunjungan+" "+uDatamainArr[vArridx].waktu_periksa_start);
		const vTglwaktukasirDT	= UFunc.toDatetime(uDatamainArr[vArridx].tgl_kunjungan+" "+uDatamainArr[vArridx].waktu_periksa_end);
		const vTglwaktuselesaiDT= UFunc.toDatetime(uDatamainArr[vArridx].tgl_kunjungan+" "+uDatamainArr[vArridx].waktu_selesai);

		return (
		<div className="classfontsmaller">
		<CRow className="align-items-stretch">
		<CCol lg="6" className="mb-3">
			<CCard className="h-100">
			<CCardHeader className="py-0 pt-1"><small>Data Pasien</small></CCardHeader>
			<CCardBody>
				<div className="d-flex justify-content-between">
					<span>{uBahasaObj.caption_namapasien||"Nama Pasien"}</span>
					<b>{uDatamainArr[vArridx].caption_pasien}</b>
				</div>
				<div className="d-flex justify-content-between">
					<span>{uBahasaObj.word_nik||"NIK"} / {uBahasaObj.word_ihs||"IHS"}</span>
					<b>{uDatamainArr[vArridx].nik_pasien||"-"} / {uDatamainArr[vArridx].ihs_pasien||"-"}</b>
				</div>

				<div className="my-3 classborderbottom"/>

				<div className="d-flex justify-content-between">
					<span>{uBahasaObj.caption_namadokter||"Nama Dokter"}</span>
					<b>{uDatamainArr[vArridx].dokter_caption}</b>
				</div>
				<div className="d-flex justify-content-between">
					<span>{uBahasaObj.word_ihs||"IHS"}</span>
					<b>{uDatamainArr[vArridx].dokter_ihs||"-"}</b>
				</div>
			</CCardBody>
			</CCard>
		</CCol>

		<CCol className="mb-3">
			<CCard className="h-100">
			<CCardHeader className="py-0 pt-1"><small>Data Kunjungan</small></CCardHeader>
			<CCardBody>
				<div className="d-flex justify-content-between">
					<span>{uBahasaObj.caption_tglkunjungan||"Tgl Kunjungan"}</span>
					<b>{UFunc.HariAngka(uDatamainArr[vArridx].tgl_kunjungan)}</b>
				</div>
				
				<div className="my-3 classborderbottom"/>

				<div className="d-flex justify-content-between">
					<span>{uBahasaObj.caption_waktuhadir||"Waktu Hadir"}</span>
					<b className="text-primary">{UFunc.JamMenit(vTglwaktuhadirDT)}</b>
				</div>
				<div className="d-flex justify-content-between">
					<span>{uBahasaObj.caption_waktuperiksa||"Waktu Periksa"}</span>
					<b className="text-warning">{UFunc.JamMenit(vTglwaktuperiksaDT)} - {UFunc.JamMenit(vTglwaktukasirDT)}</b>
				</div>
				<div className="d-flex justify-content-between">
					<span>{uBahasaObj.caption_waktuselesai||"Waktu Selesai"}</span>
					<b className="text-success">
						{(uDatamainArr[vArridx].waktu_selesai||"")==="" ? "-" : UFunc.JamMenit(vTglwaktuselesaiDT)}</b>
				</div>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>

		<CRow className="align-items-stretch">
		<CCol lg="6" className="mb-3">
			<CCard className="h-100">
			<CCardHeader className="py-0 pt-1"><small>Data Anamnase</small></CCardHeader>
			<CCardBody>
				<div>{uBahasaObj.word_anamnase||"Anamnase"}</div>
				<div className="text-end">
					<b>{uDatamainArr[vArridx].anamnase_caption||"-"}</b>
					<span className="ms-1">({uDatamainArr[vArridx].anamnase_code||"Empty Code"})</span>
				</div>
				
				<div className="my-2 classborderbottom"/>

				<div>{uBahasaObj.caption_catatandokter||"Catatan Dokter"}</div>
				<div className="text-end fst-italic">
					{((uDatamainArr[vArridx].note_dokter||"")==="" 
						&& (uDatamainArr[vArridx].note_nota||"")===""
					) ? "-" 
						: (
						<>
						<b>{uDatamainArr[vArridx].note_dokter}</b>
						{((uDatamainArr[vArridx].note_dokter||"")!=="" 
							&& (uDatamainArr[vArridx].note_nota||"")!==""
						) && (<span className="mx-1">&middot;</span>)}
						<span>{uDatamainArr[vArridx].note_nota}</span>
						</>
					)}
				</div>
			</CCardBody>
			</CCard>
		</CCol>

		<CCol className="mb-3">
			<CCard className="h-100">
			<CCardHeader className="py-0 pt-1"><small>Data Pemeriksaan</small></CCardHeader>
			<CCardBody>
				<ol className="px-0 ps-2">
				{vRMitemArr.map((vItems,vKeys)=>{
					const {
						id,terapi_caption,diagnosa_caption,diagnosa_note,keterangan,
					} = vItems;

					return (
						<li key={vKeys}>
							<b>{terapi_caption}</b>
							
							<div className="my-2 classborderbottom"/>
							
							<div className="d-flex justify-content-between">
								<small className="fst-italic">{diagnosa_caption||"-"}</small>
								<small className="fst-italic">{diagnosa_note||"-"}</small>
							</div>

							{(keterangan||"")!== "" && (
								<div>
								<CIcon icon="cilArrowRight" height={18}/>
								<b>{keterangan}</b>
								</div>
							)}
						</li>
					)
				})}
				</ol>
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>
		</div>
		)//>
	}
	const contentViewsatusehat = () => {
		if(uDlgviewdataObj.dlg_jenis !== "SATUSEHAT") return (<></>)//>

		const vTabelID	= parseInt(uDlgviewdataObj.tabelid)||0;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,vTabelID);

		const vResponseObj	= JSON.parse(uDatamainArr[vArridx].satusehat_str || "{}");

		return (
		<div style={{backgroundColor:"#FBF7F7"}} 
			className="border border-dashed rounded-3 p-3 px-5">
		<pre style={{whiteSpace:"pre-wrap",wordBeak:"break-all",}}>{JSON.stringify(vResponseObj,undefined,5)}</pre>
		</div>
		)
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//>

		if(uDlgformdataObj.dlg_jenis === "REGISTERIHS") return contentFormregisterihs();
		if(uDlgformdataObj.dlg_jenis === "SCREENING") return contentFormscreening();

		return (<></>)//>
	}
	const contentFormregisterihs = () => {
		if(uDlgformdataObj.dlg_jenis !== "REGISTERIHS") return (<></>)//>

		return (
		<CForm>
			<CRow className="my-2">
			<CCol xs="4">{uBahasaObj.word_nik || "NIK"}</CCol>
			<CCol className="">
				<CFormInput size="sm"
					value={uDlgformdataObj.nik || ""}
					onChange={(e)=>{uDlgformdataObj.nik = (e.target.value);setEfekview(!uEfekview);}}
					id="inpnik"/>
			</CCol>
			</CRow>


			<CRow className="my-2">
			<CCol xs="4">Tempat, Tgl Lahir</CCol>
			<CCol className="">
				<CFormInput size="sm"
					className="text-capitalize mb-2"
					maxLength={100}
					value={uDlgformdataObj.tempat_lahir || ""}
					onChange={(e)=>{uDlgformdataObj.tempat_lahir = (e.target.value);setEfekview(!uEfekview);}}
					autoComplete="off"
					id="inptempatlahir"/>

				<CInputGroup size="sm" className="">
				<CFormSelect size="sm"
					style={{maxWidth:80,width:"auto"}}
					value={uDlgformdataObj.tgl}
					onChange={(e)=>{uDlgformdataObj.tgl = (e.target.value);setEfekview(!uEfekview);}}
					id="inplahirtgl">
					<option>-TGL-</option>
					{(cAngkaTgl||[]).map((vItems,vKeys)=>{
						return (<option key={vKeys} value={UFunc.leadZero(vItems)}>{UFunc.leadZero(vItems)}</option>)
					})}
				</CFormSelect>
				<span className="mx-1">/</span>
				<CFormSelect size="sm"
					className="text-uppercase"
					style={{width:"auto"}}
					value={uDlgformdataObj.bln}
					onChange={(e)=>{uDlgformdataObj.bln = (e.target.value);setEfekview(!uEfekview);}}
					id="inplahirbln">
					<option>-BLN-</option>
					{(cBulanPendek||[]).map((vItems,vKeys)=>{
						return (<option className="text-uppercase" key={vKeys} value={UFunc.leadZero(vKeys+1)}>{vItems}</option>)
					})}
				</CFormSelect>
				<span className="mx-1">/</span>
				<CFormSelect size="sm"
					style={{maxWidth:95,width:"auto"}}
					value={uDlgformdataObj.thn}
					onChange={(e)=>{uDlgformdataObj.thn = (e.target.value);setEfekview(!uEfekview);}}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id="inplahirthn">
					<option>-THN-</option>
					{(uThnlahirArr||[]).map((vItems,vKeys)=>{
						return (<option key={vKeys} value={vItems}>{vItems}</option>)
					})}
				</CFormSelect>
				</CInputGroup>
			</CCol>
			</CRow>
		</CForm>
		)//>
	}
	const contentFormscreening = () => {
		if(uDlgformdataObj.dlg_jenis !== "SCREENING") return (<></>)//>

		return (
		<CForm>
		<div className="py-2 px-3 mb-3 border-top border-bottom fw-bolder">
		Screening Medis
		</div>

		<CRow className="my-2 mx-lg-0">
			<CCol xs="4" className="text-md-end">
				<span className="d-none d-md-block">Tekanan Darah</span>
				<small className="d-md-none">Tekanan Darah</small>
			</CCol>
			<CCol xs="8" md="7" lg="4" className="">
				<CInputGroup size="sm">
				<CFormInput size="sm" type="number" min={0}
					className="text-end"
					value={uDlgformdataObj.tekanandarahatas}
					onChange={(e)=>{uDlgformdataObj.tekanandarahatas=e.target.value;setEfekview(!uEfekview)}}
					id="inptekanandarahatas"/>
				<span className="mx-1">/</span>
				<CFormInput size="sm" type="number" min={0}
					className="text-end"
					value={uDlgformdataObj.tekanandarahbawah}
					onChange={(e)=>{uDlgformdataObj.tekanandarahbawah=e.target.value;setEfekview(!uEfekview)}}
					id="inptekanandarahbawah"/>
				</CInputGroup>
			</CCol>
		</CRow>

		<CRow className="my-2 mx-lg-0">
			<CCol xs="4" className="text-md-end">
				<span className="d-none d-md-block">Denyut Nadi</span>
				<small className="d-md-none">Denyut</small>
			</CCol>
			<CCol xs="8" md="5" lg="4" className="">
				<CInputGroup size="sm">
				<CFormInput size="sm" type="number" min={0}
					className="text-end"
					value={uDlgformdataObj.denyutnadi}
					onChange={(e)=>{uDlgformdataObj.denyutnadi=e.target.value;setEfekview(!uEfekview)}}
					id="inpdenyutnadi"/>
				<CInputGroupText>Detak / Menit</CInputGroupText>
				</CInputGroup>
			</CCol>
		</CRow>

		<CRow className="my-2 mx-lg-0">
			<CCol xs="4" className="text-md-end">
				<span className="d-none d-md-block">Suhu Badan</span>
				<small className="d-md-none">Suhu</small>
			</CCol>
			<CCol xs="8" md="3" lg="3" className="">
				<CInputGroup size="sm">
				<CFormInput size="sm" type="number" min={0} step={0.1}
					className="text-end"
					value={uDlgformdataObj.suhu}
					onChange={(e)=>{uDlgformdataObj.suhu=e.target.value;setEfekview(!uEfekview)}}
					id="inpsuhu"/>
				<CInputGroupText>&deg; C</CInputGroupText>
				</CInputGroup>
			</CCol>
		</CRow>

		<CRow className="my-2 mx-lg-0">
			<CCol xs="4" className="text-md-end">
				<span className="d-none d-md-block">Berat Badan</span>
				<small className="d-md-none">Berat</small>
			</CCol>
			<CCol xs="8" md="3" lg="3" className="">
				<CInputGroup size="sm">
				<CFormInput size="sm" type="number" min={0}
					className="text-end"
					value={uDlgformdataObj.berat}
					onChange={(e)=>{uDlgformdataObj.berat=e.target.value;setEfekview(!uEfekview)}}
					id="inpberat"/>
				<CInputGroupText>KG</CInputGroupText>
				</CInputGroup>
			</CCol>
		</CRow>

		<div className="py-2 px-3 my-3 border-top border-bottom fw-bolder">
		KONDISI NYERI
		</div>

		<CRow className="my-2 mx-lg-0">
			<CCol xs="4" className="text-md-end">
				<span className="d-none d-md-block">Status Nyeri</span>
				<small className="d-md-none">Status Nyeri</small>
			</CCol>
			<CCol xs="8" className="">
				<CInputGroup size="sm">
				<CInputGroupText style={{minWidth:90}} className="bg-transparent me-1">
				<input type="radio"
					value="TIDAK"
					className="me-2"
					checked={uDlgformdataObj.nyeri_status === "TIDAK"}
					onChange={(e)=>{uDlgformdataObj.nyeri_status=e.target.value;setEfekview(!uEfekview)}}
					name="inpnyeristatus"
					id="inpnyeristatustidak"/>
				<label for="inpnyeristatustidak" className="d-inline-block fw-bolder classcursorpointer">TIDAK</label>
				</CInputGroupText>

				<CInputGroupText style={{minWidth:90}} className="bg-transparent me-1">
				<input type="radio"
					value="YA"
					className="me-2"
					checked={uDlgformdataObj.nyeri_status === "YA"}
					onChange={(e)=>{uDlgformdataObj.nyeri_status=e.target.value;setEfekview(!uEfekview)}}
					name="inpnyeristatus"
					id="inpnyeristatusya"/>
				<label for="inpnyeristatusya" className="d-inline-block fw-bolder classcursorpointer">YA</label>
				</CInputGroupText>
				</CInputGroup>
			</CCol>
		</CRow>
		<CRow className="my-2 mx-lg-0">
			<CCol xs="4" className="text-md-end">
				<span className="d-none d-md-block">Skala Nyeri</span>
				<small className="d-md-none">Skala Nyeri</small>
			</CCol>
			<CCol className="">
				<CFormRange size="sm" min={0} max={10} step={1}
					value={uDlgformdataObj.nyeri_skala}
					onChange={(e)=>{uDlgformdataObj.nyeri_skala=e.target.value;setEfekview(!uEfekview)}}
					label={"(Level "+(uDlgformdataObj.nyeri_skala || 0)+")"} id="inpskalanyeri" />
			</CCol>
		</CRow>

		<CRow className="my-2 mx-lg-0">
			<CCol xs="4" className="text-md-end">
				<span className="d-none d-md-block">Kategori</span>
				<small className="d-md-none">Kategori</small>
			</CCol>
			<CCol className="">
				<CInputGroup size="sm">
				<CInputGroupText className="bg-transparent me-1 mb-1">
				<input type="radio"
					value="RINGAN"
					className="me-2"
					checked={uDlgformdataObj.nyeri_kategori === "RINGAN"}
					onChange={(e)=>{uDlgformdataObj.nyeri_kategori=e.target.value;setEfekview(!uEfekview)}}
					name="inpnyerikategori"
					id="inpnyerikategoriringan"/>
				<label for="inpnyerikategoriringan" className="d-inline-block fw-bolder classcursorpointer">RINGAN</label>
				</CInputGroupText>

				<CInputGroupText className="bg-transparent me-1 mb-1">
				<input type="radio"
					value="SEDANG"
					className="me-2"
					checked={uDlgformdataObj.nyeri_kategori === "SEDANG"}
					onChange={(e)=>{uDlgformdataObj.nyeri_kategori=e.target.value;setEfekview(!uEfekview)}}
					name="inpnyerikategori"
					id="inpnyerikategorisedang"/>
				<label for="inpnyerikategorisedang" className="d-inline-block fw-bolder classcursorpointer">SEDANG</label>
				</CInputGroupText>

				<CInputGroupText className="bg-transparent me-1 mb-1">
				<input type="radio"
					value="BERAT"
					className="me-2"
					checked={uDlgformdataObj.nyeri_kategori === "BERAT"}
					onChange={(e)=>{uDlgformdataObj.nyeri_kategori=e.target.value;setEfekview(!uEfekview)}}
					name="inpnyerikategori"
					id="inpnyerikategoriberat"/>
				<label for="inpnyerikategoriberat" className="d-inline-block fw-bolder classcursorpointer">BERAT</label>
				</CInputGroupText>
				</CInputGroup>
			</CCol>
		</CRow>

		<div className="py-2 px-3 my-3 border-top border-bottom fw-bolder">
		STATUS MENTAL
		</div>

		<CRow className="my-2 mx-lg-0">
			<CCol xs="4" className="text-md-end">
				<span className="d-none d-md-block">Emosi</span>
				<small className="d-md-none">Emosi</small>
			</CCol>
			<CCol className="">
				<CFormInput size="sm" maxLength={255}
					value={uDlgformdataObj.mental_emosi}
					onChange={(e)=>{uDlgformdataObj.mental_emosi=e.target.value;setEfekview(!uEfekview)}}
					id="inpstatusemosi"/>
			</CCol>
		</CRow>

		<CRow className="my-2 mx-lg-0">
			<CCol xs="4" className="text-md-end">
				<span className="d-none d-md-block">Konsep Diri</span>
				<small className="d-md-none">Konsep Diri</small>
			</CCol>
			<CCol className="">
				<CFormInput size="sm" maxLength={255}
					value={uDlgformdataObj.mental_konsepdiri}
					onChange={(e)=>{uDlgformdataObj.mental_konsepdiri=e.target.value;setEfekview(!uEfekview)}}
					id="inpkonsepdiri"/>
			</CCol>
		</CRow>

		<CRow className="my-2 mx-lg-0">
			<CCol xs="4" className="text-md-end">
				<span className="d-none d-md-block">Gaya Komunikasi</span>
				<small className="d-md-none">Komunikasi</small>
			</CCol>
			<CCol className="">
				<CFormInput size="sm" maxLength={255}
					value={uDlgformdataObj.mental_komunikasi}
					onChange={(e)=>{uDlgformdataObj.mental_komunikasi=e.target.value;setEfekview(!uEfekview)}}
					id="inpkomunikasi"/>
			</CCol>
		</CRow>

		<div className="py-2 px-3 my-3 border-top border-bottom fw-bolder">
		STATUS PSIKOSOSIAL-SPIRITUAL
		</div>

		<CRow className="my-2 mx-lg-0">
			<CCol xs="4" className="text-md-end">
				<span className="d-none d-md-block">Status Mental</span>
				<small className="d-md-none">Status Mental</small>
			</CCol>
			<CCol xs="8" className="">
				<CInputGroup size="sm">
				<CInputGroupText className="bg-transparent me-1 mb-1">
				<input type="radio"
					value="ORIENTASI BAIK"
					className="me-2"
					checked={uDlgformdataObj.psiko_mental === "ORIENTASI BAIK"}
					onChange={(e)=>{uDlgformdataObj.psiko_mental=e.target.value;setEfekview(!uEfekview)}}
					name="inpmental"
					id="inpmentalbaik"/>
				<label for="inpmentalbaik" className="d-inline-block fw-bolder classcursorpointer">ORIENTASI BAIK</label>
				</CInputGroupText>

				<CInputGroupText className="bg-transparent me-1 mb-1">
				<input type="radio"
					value="DIS ORIENTASI"
					className="me-2"
					checked={uDlgformdataObj.psiko_mental === "DIS ORIENTASI"}
					onChange={(e)=>{uDlgformdataObj.psiko_mental=e.target.value;setEfekview(!uEfekview)}}
					name="inpmental"
					id="inpmentaldisorientasi"/>
				<label for="inpmentaldisorientasi" className="d-inline-block fw-bolder classcursorpointer">DIS-ORIENTASI</label>
				</CInputGroupText>

				<CInputGroupText className="bg-transparent me-1 mb-1">
				<input type="radio"
					value="GELISAH"
					className="me-2"
					checked={uDlgformdataObj.psiko_mental === "GELISAH"}
					onChange={(e)=>{uDlgformdataObj.psiko_mental=e.target.value;setEfekview(!uEfekview)}}
					name="inpmental"
					id="inpmentalgelisah"/>
				<label for="inpmentalgelisah" className="d-inline-block fw-bolder classcursorpointer">GELISAH</label>
				</CInputGroupText>

				<CInputGroupText className="bg-transparent me-1 mb-1">
				<input type="radio"
					value="TIDAK RESPON"
					className="me-2"
					checked={uDlgformdataObj.psiko_mental === "TIDAK RESPON"}
					onChange={(e)=>{uDlgformdataObj.psiko_mental=e.target.value;setEfekview(!uEfekview)}}
					name="inpmental"
					id="inpmentaltidakrespon"/>
				<label for="inpmentaltidakrespon" className="d-inline-block fw-bolder classcursorpointer">TIDAK RESPON</label>
				</CInputGroupText>
				</CInputGroup>
			</CCol>
		</CRow>

		<CRow className="my-2 mx-lg-0">
			<CCol xs="4" className="text-md-end">
				<span className="d-none d-md-block">Respon Emosi</span>
				<small className="d-md-none">Respon Emosi</small>
			</CCol>
			<CCol xs="8" className="">
				<CInputGroup size="sm">
				<CInputGroupText className="bg-transparent me-1 mb-1">
				<input type="radio"
					value="TENANG"
					className="me-2"
					checked={uDlgformdataObj.psiko_emosi === "TENANG"}
					onChange={(e)=>{uDlgformdataObj.psiko_emosi=e.target.value;setEfekview(!uEfekview)}}
					name="inpemosi"
					id="inpemositenang"/>
				<label for="inpemositenang" className="d-inline-block fw-bolder classcursorpointer">TENANG</label>
				</CInputGroupText>

				<CInputGroupText className="bg-transparent me-1 mb-1">
				<input type="radio"
					value="TAKUT"
					className="me-2"
					checked={uDlgformdataObj.psiko_emosi === "TAKUT"}
					onChange={(e)=>{uDlgformdataObj.psiko_emosi=e.target.value;setEfekview(!uEfekview)}}
					name="inpemosi"
					id="inpemositakut"/>
				<label for="inpemositakut" className="d-inline-block fw-bolder classcursorpointer">TAKUT</label>
				</CInputGroupText>

				<CInputGroupText className="bg-transparent me-1 mb-1">
				<input type="radio"
					value="TEGANG"
					className="me-2"
					checked={uDlgformdataObj.psiko_emosi === "TEGANG"}
					onChange={(e)=>{uDlgformdataObj.psiko_emosi=e.target.value;setEfekview(!uEfekview)}}
					name="inpemosi"
					id="inpemositegang"/>
				<label for="inpemositegang" className="d-inline-block fw-bolder classcursorpointer">TEGANG</label>
				</CInputGroupText>

				<CInputGroupText className="bg-transparent me-1 mb-1">
				<input type="radio"
					value="MARAH"
					className="me-2"
					checked={uDlgformdataObj.psiko_emosi === "MARAH"}
					onChange={(e)=>{uDlgformdataObj.psiko_emosi=e.target.value;setEfekview(!uEfekview)}}
					name="inpemosi"
					id="inpemosimarah"/>
				<label for="inpemosimarah" className="d-inline-block fw-bolder classcursorpointer">MARAH</label>
				</CInputGroupText>

				<CInputGroupText className="bg-transparent me-1 mb-1">
				<input type="radio"
					value="SEDIH"
					className="me-2"
					checked={uDlgformdataObj.psiko_emosi === "SEDIH"}
					onChange={(e)=>{uDlgformdataObj.psiko_emosi=e.target.value;setEfekview(!uEfekview)}}
					name="inpemosi"
					id="inpemosisedih"/>
				<label for="inpemosisedih" className="d-inline-block fw-bolder classcursorpointer">SEDIH</label>
				</CInputGroupText>

				<CInputGroupText className="bg-transparent me-1 mb-1">
				<input type="radio"
					value="MENANGIS"
					className="me-2"
					checked={uDlgformdataObj.psiko_emosi === "MENANGIS"}
					onChange={(e)=>{uDlgformdataObj.psiko_emosi=e.target.value;setEfekview(!uEfekview)}}
					name="inpemosi"
					id="inpemosimenangis"/>
				<label for="inpemosimenangis" className="d-inline-block fw-bolder classcursorpointer">MENANGIS</label>
				</CInputGroupText>

				<CInputGroupText className="bg-transparent me-1 mb-1">
				<input type="radio"
					value="GELISAH"
					className="me-2"
					checked={uDlgformdataObj.psiko_emosi === "GELISAH"}
					onChange={(e)=>{uDlgformdataObj.psiko_emosi=e.target.value;setEfekview(!uEfekview)}}
					name="inpemosi"
					id="inpemosigelisah"/>
				<label for="inpemosigelisah" className="d-inline-block fw-bolder classcursorpointer">GELISAH</label>
				</CInputGroupText>
				</CInputGroup>
			</CCol>
		</CRow>

		<div className="py-2 px-3 my-3 border-top border-bottom fw-bolder">
		SOSIAL BUDAYA
		</div>

		<CRow className="my-2 mx-lg-0">
			<CCol xs="4" className="text-md-end">
				<span className="d-none d-md-block">Agama</span>
				<small className="d-md-none">Agama</small>
			</CCol>
			<CCol className="">
				<CFormInput size="sm" maxLength={100}
					value={uDlgformdataObj.sosial_agama}
					onChange={(e)=>{uDlgformdataObj.sosial_agama=e.target.value;setEfekview(!uEfekview)}}
					id="inpsosialagama"/>
			</CCol>
		</CRow>

		<CRow className="my-2 mx-lg-0">
			<CCol xs="4" className="text-md-end">
				<span className="d-none d-md-block">Pendidikan Terakhir</span>
				<small className="d-md-none">Pendidikan</small>
			</CCol>
			<CCol className="">
				<CFormInput size="sm" maxLength={100}
					value={uDlgformdataObj.sosial_pendidikan}
					onChange={(e)=>{uDlgformdataObj.sosial_pendidikan=e.target.value;setEfekview(!uEfekview)}}
					id="inpsosialpendidikan"/>
			</CCol>
		</CRow>

		<CRow className="my-2 mx-lg-0">
			<CCol xs="4" className="text-md-end">
				<span className="d-none d-md-block">Pekerjaan</span>
				<small className="d-md-none">Pekerjaan</small>
			</CCol>
			<CCol className="">
				<CFormInput size="sm" maxLength={100}
					value={uDlgformdataObj.sosial_pekerjaan}
					onChange={(e)=>{uDlgformdataObj.sosial_pekerjaan=e.target.value;setEfekview(!uEfekview)}}
					id="inpsosialpekerjaan"/>
			</CCol>
		</CRow>

		<div className="py-2 px-3 my-3 border-top border-bottom fw-bolder">
		LAINNYA
		</div>

		<CRow className="my-2 mx-lg-0">
			<CCol xs="4" className="text-md-end pe-0 pe-md-3">
				<span className="d-none d-md-block">Riwayat Kesehatan</span>
				<small className="d-md-none">Rwyt Kesehatan</small>
			</CCol>
			<CCol className="">
				<CFormInput size="sm" maxLength={100}
					value={uDlgformdataObj.riwayat_kesehatan}
					onChange={(e)=>{uDlgformdataObj.riwayat_kesehatan=e.target.value;setEfekview(!uEfekview)}}
					id="inpriwayatkesehatan"/>
			</CCol>
		</CRow>

		<CRow className="my-2 mx-lg-0">
			<CCol xs="4" className="text-md-end">
				<span className="d-none d-md-block">Riwayat <span className="me-1 d-none d-lg-inline">Penggunaan</span>Obat</span>
				<small className="d-md-none">Riwayat Obat</small>
			</CCol>
			<CCol className="">
				<CFormInput size="sm" maxLength={100}
					value={uDlgformdataObj.riwayat_obat}
					onChange={(e)=>{uDlgformdataObj.riwayat_obat=e.target.value;setEfekview(!uEfekview)}}
					id="inpriwayatobat"/>
			</CCol>
		</CRow>

		<CRow className="my-2 mx-lg-0">
			<CCol xs="4" className="text-md-end">
				<span className="d-none d-md-block">Screening Gizi</span>
				<small className="d-md-none">Screening Gizi</small>
			</CCol>
			<CCol className="">
				<CFormInput size="sm" maxLength={100}
					value={uDlgformdataObj.riwayat_gizi}
					onChange={(e)=>{uDlgformdataObj.riwayat_gizi=e.target.value;setEfekview(!uEfekview)}}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id="inpscreeninggizi"/>
			</CCol>
		</CRow>
		</CForm>
		)//>
	}
	//--END CONTENT_FUNC--/

	//--API--/
	const apiLoaddata = (_PAGE) => {
		_PAGE	= parseInt(_PAGE)||1;
		setHtml500msg();

		const vKeyword 		= vFilterviewObj.kwd_statuspasien || "";
		const vCabangID 	= parseInt(vFilterviewObj.cabang_id || uTokenObj.user_cabangid);

		let vDatamainArr	= uDatamainArr;
		if(vKeyword.length < 3) {
			vDatamainArr 	= []
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= vDatamainArr.filter(vItems=>parseInt(vItems.page||1) === 1);
			} else
				vDatamainArr	= vDatamainArr.filter(vItems=>parseInt(vItems.page||1) !== uPageActive);
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},3500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_cabangid : vCabangID,
			send_keyword : vKeyword,
			send_page: _PAGE,
			send_jmlperpage: uMaxData,
			send_loadjml: (JSON.stringify(uLastinitObj||{}) !== "{}") ? "YA" : "TIDAK",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_statuspasien/ld_data";

		//console.log("(Statuspasien - apiLoaddata) uLastinitObj : "+JSON.stringify(uLastinitObj));
		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				//setSql((output_string.sql||"--EMPTY--"))
				const vTmpArr = JSON.parse(output_string.dataobject||"[]");
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata || uJmlData))
				else setJmlData(vTmpArr.length);

				setDatamainArr([...vDatamainArr,...vTmpArr]);
				//console.log("(Statuspasien - apiLoaddata) uPageActive : "+(uPageActive));
		    	if(JSON.stringify(uLastinitObj||{}) !== "{}") 
		    		setInitselectedObj(uLastinitObj);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Statuspasien - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Statuspasien - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadkunjungan = () => {
		const vTabelID	= parseInt(uDlgviewdataObj.tabelid)||0;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,vTabelID);
		const vCaptionUpper = (uDatamainArr[vArridx].caption_pasien||"Undf Pasien").toUpperCase();
		const vCabangID = parseInt(vFilterviewObj.cabang_id || uTokenObj.user_cabangid);

		uDatamainArr[vArridx].is_processing = true;
		setLoading(true);
		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			uDatamainArr[vArridx].is_processing = false;
			setLoading(false);

			uDlgviewdataObj.dlg_header 	= "Data Kunjungan: "+vCaptionUpper;
			uDlgviewdataObj.dlg_jenis 	= "KUNJUNGAN";

			setDlgviewshow(true);
		},3500); return//-*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelID,
			send_cabangid : vCabangID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_statuspasien/ld_kunjungan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();}})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vArridx].is_processing = false;
			setLoading(false);
			if(output_string.tampil) {
				uDatamainArr[vArridx].rmitem_str 		= output_string.rmitem_str || "";
				uDatamainArr[vArridx].anamnase_code 	= output_string.anamnase_code || "";
				uDatamainArr[vArridx].anamnase_caption 	= output_string.anamnase_caption || "";
				uDatamainArr[vArridx].note_dokter 		= output_string.note_dokter || "";
				uDatamainArr[vArridx].note_nota 		= output_string.note_nota || "";

				uDlgviewdataObj.dlg_header 	= "Data Kunjungan: "+vCaptionUpper;
				uDlgviewdataObj.dlg_jenis 	= "KUNJUNGAN";

				setDlgviewshow(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Statuspasien - apiLoadkunjungan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_processing = false;
			setLoading(false);
			console.log("(Statuspasien - apiLoadkunjungan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoadsatusehat = () => {
		const vTabelID	= parseInt(uDlgviewdataObj.tabelid)||0;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,vTabelID);
		const vCaptionUpper = (uDatamainArr[vArridx].caption_pasien||"Undf Pasien").toUpperCase();
		const vCabangID = parseInt(vFilterviewObj.cabang_id || uTokenObj.user_cabangid);

		uDatamainArr[vArridx].is_processing = true;
		setLoading(true);
		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			uDatamainArr[vArridx].is_processing = false;
			setLoading(false);
		},3500); return//-*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelID,
			send_cabangid : vCabangID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_statuspasien/ld_satusehat";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();}})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vArridx].is_processing = false;
			setLoading(false);
			if(output_string.tampil) {
				uDatamainArr[vArridx].satusehat_str 	= output_string.satusehat_str || "";

				uDlgviewdataObj.dlg_header 	= "Response Satusehat: "+vCaptionUpper;
				uDlgviewdataObj.dlg_jenis 	= "SATUSEHAT";

				setDlgviewshow(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Statuspasien - apiLoadkunjungan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_processing = false;
			setLoading(false);
			console.log("(Statuspasien - apiLoadkunjungan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiBatalhadir	= (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		//const vTabelID	= parseInt(uDlgformdataObj.tabelid)||0; if(vTabelID <= 0) return;
		const vCabangID = parseInt(vFilterviewObj.cabang_id || uTokenObj.user_cabangid);
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		uDatamainArr[vArridx].is_processing = true;
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uDatamainArr[vArridx].id_antrian,
			send_cabangid : vCabangID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_antrian/pr_hadir";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();}})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vArridx].is_processing = false;
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr.splice(vArridx,1);
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Statuspasien - apiBatalhadir) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_processing = false;
			setLoading(false);
			console.log("(Statuspasien - apiBatalhadir) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiCheckingperiksa = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vPasienID = parseInt(uDatamainArr[vArridx].id_pasien)||0; 

		uDatamainArr[vArridx].is_processing = true;
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(async()=>{
			clearTimeout(vTimeout);
			setActionprocess(false);
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_pasienid : vPasienID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/pr_checking";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then(async(output_string) => {
			uDatamainArr[vArridx].is_processing = false;
			setLoading(false)
			if(output_string.tampil) {
				if(output_string.init_start) {
					if(await( Konfirm("<b>Anda Akan memulai Billing Pemeriksaan</b>.."+
						"<br/>Apakah Anda Yakin ?"+
						"") )) {
						apiProsesperiksa(_IDTABEL)
						return;
					} 
					setSessionaktif(true);
				} else if(output_string.init_continue) {
					uNavigate("/subpasien/pasien/rekammedis/"+vPasienID)
				} else if(output_string.init_stillprocess) {
					showToast("<b>Maaf, Pasian Sedang Dalam Proses</b>.."+
						"<br/><br/>Billing Pemeriksaan PASIEN tersebut sedang dilakukan oleh PENGGUNA LAIN."+
						"<br/>Silahkan Tunggu Sejenak, Atau Hubungi PENGELOLA untuk MEMBATALKANNYA !"+
						"");
				}
			} else {
				setSessionaktif(true);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Statuspasien - apiCheckingperiksa) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_processing = false;
			setLoading(false)

			console.log("(Statuspasien - apiCheckingperiksa) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesperiksa = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vPasienID = parseInt(uDatamainArr[vArridx].id_pasien)||0; 

		uDatamainArr[vArridx].is_processing = true;
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			uNavigate("/subpasien/pasien/periksa/"+uPasienID)
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_pasienid : vPasienID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/pr_periksa";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			if(output_string.sukses) {
				uNavigate("/subpasien/pasien/rekammedis/"+vPasienID); return;
			} else if(output_string.expired) {
				prosesExpired();
			} else {
				setSessionaktif(true);
				uDatamainArr[vArridx].is_processing = false;
				setLoading(false);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Statuspasien - apiProsesperiksa) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				}
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_processing = false;
			setLoading(false);
			console.log("(Statuspasien - apiProsesperiksa) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiBatalperiksa = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)

		uDatamainArr[vArridx].is_processing = true;
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			uDatamainArr[vArridx].is_processing = true;
			setLoading(false)
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uDatamainArr[vArridx].id_pasien || 0,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/pr_prosesbatal";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vArridx].is_processing = false;
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr[vArridx].waktu_periksa_start = "";
			
				setEfekupdate(!uEfekupdate);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Statuspasien - apiBatalperiksa) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_processing = false;
			setLoading(false);
			console.log("(Statuspasien - apiBatalperiksa) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesscreening = () => {
		const vTabelID 	= parseInt(uDlgformdataObj.tabelid)||0; if(vTabelID <= 0) return;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,vTabelID);

		const vScreeningObj	= {
			tekanandarahatas : uDlgformdataObj.tekanandarahatas,
			tekanandarahbawah : uDlgformdataObj.tekanandarahbawah,
			denyutnadi : uDlgformdataObj.denyutnadi,
			suhu : uDlgformdataObj.suhu,
			berat : parseFloat(uDlgformdataObj.berat) <= 0 ? undefined : parseFloat(uDlgformdataObj.berat),
			nyeri_status : uDlgformdataObj.nyeri_status,
			nyeri_skala : parseInt(uDlgformdataObj.nyeri_skala) <= 0 ? undefined : uDlgformdataObj.nyeri_skala,
			nyeri_kategori : uDlgformdataObj.nyeri_kategori,
			mental_emosi : (uDlgformdataObj.mental_emosi||"").trim()==="" ? undefined : (uDlgformdataObj.mental_emosi||"").trim(),
			mental_konsepdiri : (uDlgformdataObj.mental_konsepdiri||"").trim()==="" ? undefined : (uDlgformdataObj.mental_konsepdiri||"").trim(),
			mental_komunikasi : (uDlgformdataObj.mental_komunikasi||"").trim()==="" ? undefined : (uDlgformdataObj.mental_komunikasi||"").trim(),
			psiko_mental : uDlgformdataObj.psiko_mental,
			psiko_emosi : uDlgformdataObj.psiko_emosi,
			sosial_agama : (uDlgformdataObj.sosial_agama||"").trim()==="" ? undefined : (uDlgformdataObj.sosial_agama||"").trim(),
			sosial_pendidikan : (uDlgformdataObj.sosial_pendidikan||"").trim()==="" ? undefined : (uDlgformdataObj.sosial_pendidikan||"").trim(),
			sosial_pekerjaan : (uDlgformdataObj.sosial_pekerjaan||"").trim()==="" ? undefined : (uDlgformdataObj.sosial_pekerjaan||"").trim(),
			riwayat_kesehatan : (uDlgformdataObj.riwayat_kesehatan||"").trim()==="" ? undefined : (uDlgformdataObj.riwayat_kesehatan||"").trim(),
			riwayat_obat : (uDlgformdataObj.riwayat_obat||"").trim()==="" ? undefined : (uDlgformdataObj.riwayat_obat||"").trim(),
			riwayat_gizi : (uDlgformdataObj.riwayat_gizi||"").trim()==="" ? undefined : (uDlgformdataObj.riwayat_gizi||"").trim(),
		}
		const vElbutton = document.getElementById("btnDialogSimpan");

		vElbutton && (vElbutton.disabled = true);
		uDatamainArr[vArridx].is_processing = true;
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			uDatamainArr[vArridx].is_processing = false;
			clearTimeout(vTimeout);
			setLoading(false)

			showToast(JSON.stringify(vScreeningObj)); return;
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelID,
			send_datajson : JSON.stringify(vScreeningObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_statuspasien/pr_prosesscreening";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			vElbutton && (vElbutton.disabled = false);
			uDatamainArr[vArridx].is_processing = false;
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr[vArridx].screening_str = JSON.stringify(vScreeningObj || {});
				setDlgformshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Statuspasien - apiProsesscreening) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElbutton && (vElbutton.disabled = false);
			uDatamainArr[vArridx].is_processing = false;
			setLoading(false);
			console.log("(Statuspasien - apiProsesscreening) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesihs = () => {
		const vTabelID 			= parseInt(uDlgformdataObj.tabelid)||0; if(vTabelID <= 0) return;
		const vAccesstoken 		= uTokensatusehatObj.access_token;
		
 		if(!vAccesstoken) {
 			loadToken(apiProsesihs); return;
 		}
		const vLimitedsec		= parseInt(uTokensatusehatObj.expires_in || "0");
		const vTimestampnow 	= new Date();
		const vTimetoken		= new Date(parseInt(uTokensatusehatObj.issued_at || "0"));
		const vSelisih			= UFunc.Datetimeselisih(vTimetoken,vTimestampnow).dtk;

		if(vSelisih > vLimitedsec) {
 			loadToken(apiProsesihs); return;
		}

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,vTabelID);
		const vPasienID = parseInt(uDatamainArr[vArridx].id_pasien)||0;

		let vTanggallahir 	= uDlgformdataObj.thn+"-"+uDlgformdataObj.bln+"-"+uDlgformdataObj.tgl;
		const visValiddate = parseInt(uDlgformdataObj.thn) > 0 
			&& (parseInt(uDlgformdataObj.bln) > 0 && parseInt(uDlgformdataObj.bln) <= 12)
			&& (parseInt(uDlgformdataObj.tgl) > 0 && parseInt(uDlgformdataObj.tgl) <= 31);
		if(!visValiddate) vTanggallahir = "";

		//const vTanggallahirDT 	= new Date(vTanggallahir)
		if(!UFunc.isDateValid(vTanggallahir)) {
			vTanggallahir = "";
		}

		const vTmpObj = {
			nik : uDlgformdataObj.nik,
			tempat_lahir : uDlgformdataObj.tempat_lahir,
			tgl_lahir : vTanggallahir,
		}

		const vElDlgbutton = document.getElementById("btnDialogSimpan");
		vElDlgbutton && (vElDlgbutton.disabled = true);
		setLoading(true);

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);

			initFormawal();
			showToast(uBahasaObj.pesan_sukses,"SUKSES")
		},2000); return;//--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vPasienID,
			send_newdatajson: JSON.stringify(vTmpObj),
			send_accesstoken : vAccesstoken,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/pr_registerihs";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			vElDlgbutton && (vElDlgbutton.disabled = false);
			setLoading(false);

			const vIHSnumber	= output_string.ihs_number || "";
			const vIHSmessage 	= output_string.ihs_message || "";

			if(output_string.sukses) {
				uDatamainArr[vArridx].ihs_pasien 			= vIHSnumber;
				uDatamainArr[vArridx].nik_pasien 			= uDlgformdataObj.nik;
				uDatamainArr[vArridx].tempatlahir_pasien 	= uDlgformdataObj.tempat_lahir;
				uDatamainArr[vArridx].tgllahir_pasien 		= vTanggallahir;

				if(vIHSmessage !== "")
					showToast("SATUSEHAT: <b>"+vIHSmessage+"</b>","SUKSES");

				setDlgformshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Statuspasien - apiProsesihs) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElDlgbutton && (vElDlgbutton.disabled = false);
			setLoading(false);
			console.log("(Statuspasien - apiProsesihs) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsessatusehat = (_IDTABEL) => {
		const vTabelID 			= parseInt(_IDTABEL)||0; if(vTabelID <= 0) return;
		const vAccesstoken 		= uTokensatusehatObj.access_token;
		
 		if(!vAccesstoken) {
 			loadToken(apiProsesihs); return;
 		}
		const vLimitedsec		= parseInt(uTokensatusehatObj.expires_in || "0");
		const vTimestampnow 	= new Date();
		const vTimetoken		= new Date(parseInt(uTokensatusehatObj.issued_at || "0"));
		const vSelisih			= UFunc.Datetimeselisih(vTimetoken,vTimestampnow).dtk;

		if(vSelisih > vLimitedsec) {
 			loadToken(apiProsesihs); return;
		}

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,vTabelID);

		uDatamainArr[vArridx].is_processing = true;
		setLoading(true);

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);

			initFormawal();
			showToast(uBahasaObj.pesan_sukses,"SUKSES")
		},2000); return;//--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelID,
			send_accesstoken : vAccesstoken,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_statuspasien/pr_kirimsatusehat";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vArridx].is_processing = false;
			setLoading(false);

			if(output_string.sukses) {
				const vIHSmessage 	= output_string.ihs_message || "";

				uDatamainArr[vArridx].satusehat_status 	= "SUDAH";
				uDatamainArr[vArridx].encounter_uid 	= output_string.encounter_uid || uDatamainArr[vArridx].encounter_uid;

				if(vIHSmessage !== "")
					showToast("SATUSEHAT: <b>"+vIHSmessage+"</b>","SUKSES");

				setEfekupdate(!uEfekupdate);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Statuspasien - apiProsessatusehat) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_processing = false;
			setLoading(false);
			console.log("(Statuspasien - apiProsessatusehat) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/


	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	//const vPageinit	= uPageActive;
		//console.log("(Pasien - []) uLastinitObj.initPage => "+JSON.stringify(uLastinitObj))

		/*//--PAGE_LOAD_ALL--/
		apiLoaddata();
		//--END PAGE_LOAD_ALL--*/

		//--PAGE_LOAD_PER_PAGE--/
		if(JSON.stringify(uLastinitObj||{}) !== "{}") {
			setPageActive(uLastinitObj.initPage||1);
			apiLoaddata(uLastinitObj.initPage||1);
		}
		//--END PAGE_LOAD_PER_PAGE--/

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
    		setDatamainArr([]);
    		setDlgviewshow(false);
    		setInitselectedObj({}); 
    		setDlgformshow(false); setPageActive(0);
    		/*vFilterviewObj.cabang_id = uTokenObj.user_cabangid;
			uKeywordObj.filter_statuskunjungan = vFilterviewObj;
    		localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));//-*/
    	}
	},[])
	React.useEffect(()=>{ 
    	if(JSON.stringify(uLastinitObj||{}) !== "{}") return;
    	if(uPageActive <= 0) { setPageActive(1); return }

		setRowselect(-1); 
		const vDataviewArr	= uDatamainArr.filter(vItems=>parseInt(vItems.page||1) === parseInt(uPageActive));
		if(vDataviewArr.length > 0) {
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}
		apiLoaddata(uPageActive);
	},[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{ 
		uDatamainArr.map(vItems => {
			if((vItems.waktu_periksa_start||"")!=="" && (vItems.waktu_periksa_end||"")!==""
				&& (vItems.waktu_selesai||"")!==""
				) {
				vItems.status_name 	= "SELESAI";
			} else if((vItems.waktu_periksa_start||"")!=="" && (vItems.waktu_periksa_end||"")!==""
				&& (vItems.waktu_selesai||"")===""
				) {
				vItems.status_name 	= "KASIR";
			}else if((vItems.waktu_periksa_start||"")!=="" && (vItems.waktu_periksa_end||"")===""
				) {
				vItems.status_name 	= "PERIKSA";
			} else {
				vItems.status_name 	= "HADIR";
			}
			//vItems.status_name 	= "PERIKSA";
			//vItems.encounter_uid 	= "";
		})
	},[uDatamainArr,uDatamainArr.length,uEfekupdate])
	React.useEffect(()=>{ 
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/

		/*const vKeyword 		= vFilterviewObj.kwd_statuspasien || "";
		if(vKeyword.length < 3) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uElJmlData,uJmlData])
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		//setElJmlData(null);
		setDatamainArr([]);
		setJmlData(0);
		setIDselect(0);
		setRowselect(-1);

		//console.log("(Pasien - [uHandelView]) uHandelView => "+uHandelView)
		if(parseInt(uPageActive) !== 1) {
			setPageActive(1);
		} else apiLoaddata();
	},[uHandelView])
	/*React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHtml500msg) return;

		uNavigate("/subpasien/pasieninput");
	},[uHandelTambah])//-*/
	React.useEffect(()=>{
		uDispatch({type: "set", gInitTabelSelected: {}});
		if(JSON.stringify(uInitselectedObj||{}) === "{}") return;

		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+(uInitselectedObj.initID||0));
		setRowselect(vIdxRow);
		
		const vElscroll	= document.getElementById("idtr"+(uInitselectedObj.initID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Pasien - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))

		setInitselectedObj({});
	},[uInitselectedObj])
	React.useEffect(()=>{
		const vBatasyear= parseInt(uSystemsettingObj.start_tahunlahir || "1950");
		const vYearnow	= (new Date).getFullYear();
		const vTmptahun	= [];
		for(var vIdx=vYearnow;vIdx >= vBatasyear; vIdx--)
			vTmptahun.push(vIdx);
		setThnlahirArr(vTmptahun);
	},[uSystemsettingObj])
	React.useEffect(()=>{
		if(!uISDlgformshow) { setDlgformdataObj({}); return }

		let vTimeout = setTimeout(()=>{
			clearTimeout();
			let vElfocus	= document.getElementById("inpnik");
			if(uDlgformdataObj.dlg_jenis === "SCREENING")
				vElfocus	= document.getElementById("inptekanandarahatas");

			if(!isMobile) vElfocus && vElfocus.focus();
		},350)
	},[uISDlgformshow])
	React.useEffect(()=>{
		if(!uISDlgviewshow) 
			{ setDlgviewdataObj({}); return }
	},[uISDlgviewshow])
	React.useEffect(()=>{
		if(!uISDlgpasienshow) 
			{ setDlgPasienID(0); return }
	},[uISDlgpasienshow])

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<b>{uBahasaObj.caption_page500||"Page 500"}</b>
		</CCardHeader>
		<CCardBody className="p-lg-5">{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	return (
		<>
		<CCard className="classbgcard">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<div>
		<b className="align-middle">
			{uBahasaObj.caption_listdata||"List Data"}
		</b>

		<span className="mx-1 d-md-none">&middot;</span>
		<small className="d-md-none text-primary align-middle">{(()=>{
			//const vKeyword 		= (vFilterviewObj.filter_statuskunjungan || "").trim();
			//if(vKeyword.length < 3) return (<>&#8734;</>);

			return (<>[{UFunc.formatAngka(uJmlData)}]</>)
		})()}</small>
		</div>

		<div className="d-none">{JSON.stringify(uInitselectedObj)}</div>
		<small className="d-none">({uJmlData} - {uMaxData})</small>

		<span className="text-muted classfontsmaller">
			<small className="fw-normal text-primary">
			{uDatacabangArr.filter(vItems => 
				parseInt(vItems.id) === 
				parseInt(vFilterviewObj.cabang_id || uTokenObj.user_cabangid))[0].cabang_nama || "Undf"}
			</small>
			<span className="mx-1">&middot;</span>
			<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
		</span>
		</CCardHeader>
		
		<CCardBody className="px-0">{contentMain()}</CCardBody>

		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/> 
		</CCardFooter>
		)}
		</CCard>

		<MyDialogform
			options={{size:uDlgformdataObj.dlg_size,centered:false}}
			onSimpan={()=>hdlKlikDlgsimpan()}
			show={uISDlgformshow} 
			dialogHeader={uDlgformdataObj.dlg_header}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>

		<MyDialogview
			options={{size:"lg",centered:false}}
			show={uISDlgviewshow} 
			dialogHeader={uDlgviewdataObj.dlg_header}
			toggle={()=>setDlgviewshow(false)}
			renderContent={contentDlgview()}/>

		<MyDialogpasien show={uISDlgpasienshow}
			pasienID={uDlgPasienID}
			toggle={()=>setDlgpasienshow(false)}
			{...props}/>
		</>
	)//>
}	

export default Statuspasien